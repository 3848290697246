import { useEffect, useState } from "react";

import { OverlaySpinner } from "components";
import { Alert } from "flowbite-react";
import {
  useFetchSuppliersWidgetData,
  useSyncVerifyPartnerAccount,
  useVerifyPartnerAccount,
} from "hooks";
import { PageHeader } from "layouts";
import { HiCheckCircle, HiExclamation, HiOfficeBuilding } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { AuthService } from "services";

import { AdminSettingsOrganisationTab, AdminSettingsUserTab } from "./tabs";

const TABS = ["Your Organisation", "Users"];
const TAB_SLUGS = ["organisation", "users"];

export function AdminSettingsPage() {
  const verifyPartner = useVerifyPartnerAccount();
  const supplierData = useFetchSuppliersWidgetData();
  const navigate = useNavigate();
  const syncPartnerState = useSyncVerifyPartnerAccount();

  const { tab } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [currentTab, setCurrentTab] = useState(
    !!tab ? TAB_SLUGS.indexOf(tab) : 0
  );
  const [showPasswordResetAlert, setShowPasswordResetAlert] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const handlePasswordReset = async () => {
    // setIsLoading(true);
    await AuthService.forgotPassword();
    setShowPasswordResetAlert(true);
    // setIsLoading(false);
  };

  const handleSync = async (msg) => {
    setIsLoading(true);
    await syncPartnerState();
    setInfoMsg(msg);
    setIsLoading(false);
  };

  const handleTabChange = (index) => {
    setCurrentTab(index);
    navigate(`/admin/settings/${TAB_SLUGS[index]}`, { replace: true });
  };

  useEffect(() => {
    if (!!verifyPartner?.data && !!supplierData?.data) {
      setData(verifyPartner?.data);
      setIsLoading(false);
    }
  }, [verifyPartner?.data, supplierData?.data]);

  return (
    <>
      {isLoading && <OverlaySpinner />}
      <PageHeader
        variant="primePage"
        pageName="Settings"
        pageDescription="Manage your organisation and user profile details."
      />
      {!!infoMsg && (
        <Alert
          color="green"
          onDismiss={() => setInfoMsg("")}
          icon={HiCheckCircle}
        >
          <span className="text-base font-semibold">{infoMsg}</span>
        </Alert>
      )}
      {!!errMsg && (
        <Alert color="red" onDismiss={() => setErrMsg("")} icon={HiExclamation}>
          <span className="text-base font-semibold">{errMsg}</span>
        </Alert>
      )}
      {showPasswordResetAlert && (
        <Alert
          color="green"
          onDismiss={() => setShowPasswordResetAlert(false)}
          icon={HiCheckCircle}
          additionalContent={
            <>
              An email containing further instructions to reset your password
              has been sent to{" "}
              <span className="font-semibold">{data?.user?.email}</span>.
              <br />
              If you did not receive this email, please{" "}
              <button className="font-semibold hover:opacity-70 focus:underline">
                resend
              </button>{" "}
              or{" "}
              <button className="font-semibold hover:opacity-70 focus:underline">
                contact our support
              </button>{" "}
              for assistance.
            </>
          }
        >
          <span className="font-semibold">
            Password reset request has been processed.
          </span>
        </Alert>
      )}
      <section id="cybercert-admin-settings-header">
        <div className="flex flex-col self-stretch">
          <div className="surface-db-stepper flex flex-row items-center gap-4 self-stretch rounded-t-lg px-5 pb-8 pt-5 shadow">
            <div className="border-secondary size-15 flex items-center justify-center rounded-full border">
              {!!data?.partner?.faviconUrl ? (
                <img
                  src={data?.partner?.faviconUrl}
                  alt={`${data?.organisation?.name} Avatar`}
                  className="h-7 w-7"
                />
              ) : (
                <HiOfficeBuilding className="text-icons h-7 w-7" />
              )}
            </div>
            <div className="my-auto flex flex-col gap-1 self-stretch">
              <span className="text-primary text-lg font-semibold">
                {data?.organisation?.name}
              </span>

              <span className="text-gray text-sm leading-none">
                <span className="font-semibold">ABN</span>{" "}
                {data?.organisation?.ABN}
              </span>
            </div>
          </div>

          {/* Laptop navigation tabs */}
          <div className="hidden laptop:flex surface-sub h-fit items-start gap-5 self-stretch rounded-b-lg p-4 tablet:h-[59px] flex-row tablet:items-center w-full">
            {TABS?.map((item, index) => (
              <button
                key={index}
                disabled={index === currentTab}
                className="rounded-lg px-4 py-2 text-base font-bold outline-none ring-white focus-visible:ring-1 enabled:hover:opacity-80"
                onClick={() => handleTabChange(index)}
              >
                <span
                  className={index === currentTab ? "text-white" : "text-icons"}
                >
                  {item}
                </span>
              </button>
            ))}
          </div>
        </div>
      </section>
      {
        [
          <AdminSettingsOrganisationTab
            data={data}
            setIsLoading={setIsLoading}
            handleSync={() =>
              handleSync("Organisation details updated successfully!")
            }
          />,
          <AdminSettingsUserTab
            data={data}
            handlePasswordReset={handlePasswordReset}
            handleSync={() => handleSync("User details updated successfully!")}
          />,
        ][currentTab]
      }
    </>
  );
}
