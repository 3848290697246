import { useEffect, useState } from "react";

import { verifyGAPAssessmentToken } from "api";
import { LoadingSpinner } from "components";
import { Alert } from "flowbite-react";
import { Footer } from "layouts";
import { LayoutAssessmentWrapper } from "layouts/layout-wrapper/layout-assessment-wrapper";
import { HiExclamationCircle } from "react-icons/hi";
import { useLocation, useParams } from "react-router-dom";
import { deepClone } from "utils";

import { CheckEligibility } from "./check-eligibility";
import { ConfirmOrg } from "./confirm-org";
import { MainAssessment } from "./main-assessment";
import { ROUTES } from "variables";

const INITIAL_VERIFY_INPUT = {
  name: { error: false, value: "" },
  email: { error: false, value: "" },
  phone: { error: false, value: "" },
};

export function PartnerLinksPage() {
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false);
  const [step, setStep] = useState("verify"); // verify / confirm / assessment
  const [verifyInput, setVerifyInput] = useState(
    deepClone(INITIAL_VERIFY_INPUT) // {name, phone, email}
  );
  const [verifyResult, setVerifyResult] = useState();
  const [showVerifyError, setShowVerifyError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  const params = useParams();

  const handleVerifyInput = (event) => {
    setVerifyInput((prevInput) => ({
      ...prevInput,
      [event.target.name]: {
        error: false,
        value: event.target.value,
      },
    }));
  };

  const setup = async () => {
    setIsLoading(true);

    const result = await verifyGAPAssessmentToken({
      token: params?.token,
      preview: state?.preview,
    });
    console.log("result", result);

    if (result?.redirect) {
      window.location.href = ROUTES.ASSESSMENT_LINK.replace(
        ":token",
        result.redirect
      );
    } else if (!result?.partner) {
      window.location.href = ROUTES.ASSESSMENT_404;
    } else {
      setVerifyResult(result);
      if (!!state?.preview && !!result?.assessmentResult) {
        setStep("assessment");
      }
    }
    setIsLoading(false);
  };

  const verify = async (input) => {
    setShowVerifyError(false);
    setIsVerifying(true);

    if (!!params?.token) {
      const result = await verifyGAPAssessmentToken({
        token: params?.token,
        phone: input?.phone?.value,
        email: input?.email?.value,
        name: input?.name?.value,
      });

      if (!!result?.assessmentResult) {
        setVerifyResult(result);
        setStep("confirm");
      } else {
        setShowVerifyError(true);
      }
    }

    setIsVerifying(false);
  };

  const returnToDashboard = () => {};

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (Object.values(verifyInput).every((field) => !field.error)) {
      setErrMsg(null);
    }
  }, [verifyInput]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <LayoutAssessmentWrapper
      assessment={verifyResult}
      eligibilityCheck={step.includes("assessment")}
      preview={!!state?.preview}
      partner={verifyResult?.partner}
      returnFn={returnToDashboard}
    >
      {isVerifying ? (
        <LoadingSpinner />
      ) : (
        <div className="flex h-full w-full flex-col justify-between">
          {
            {
              verify: (
                <div className="flex flex-col gap-0">
                  {showVerifyError && (
                    <div className="p-8 pb-0">
                      <Alert
                        color={"red"}
                        onDismiss={() => setShowVerifyError(false)}
                        icon={HiExclamationCircle}
                        additionalContent={
                          <p>
                            Please ensure that your email address is entered
                            correctly and is the one registered with your
                            organization.
                            <br />
                            If you have confirmed your details and still
                            encounter this message, please contact your
                            organization's administrator or our support team for
                            assistance.
                          </p>
                        }
                      >
                        <span className="text-base font-semibold">
                          We are unable to confirm your eligibility with the
                          details provided.
                        </span>
                      </Alert>
                    </div>
                  )}

                  {!!errMsg && (
                    <div className="p-8 pb-0">
                      <Alert
                        color={"red"}
                        onDismiss={() => setErrMsg(null)}
                        icon={HiExclamationCircle}
                      >
                        {errMsg}
                      </Alert>
                    </div>
                  )}

                  <CheckEligibility
                    input={verifyInput}
                    setInput={setVerifyInput}
                    setErrMsg={setErrMsg}
                    handleInput={handleVerifyInput}
                    handleVerify={verify}
                  />
                </div>
              ),
              confirm: (
                <ConfirmOrg
                  verifyResult={verifyResult}
                  handleConfirm={() => setStep("assessment")}
                />
              ),
              assessment: <MainAssessment assessment={verifyResult} />,
            }[step]
          }

          <Footer />
        </div>
      )}
    </LayoutAssessmentWrapper>
  );
}
