import {
  BronzeCertificate2025,
  BronzeSampleCertificate,
  DiamondCertificate2025,
  GoldCertificate2025,
  GoldSampleAttestation,
  GoldSampleCertificate,
  PlatinumCertificate2025,
  SilverCertificate2025,
  SilverSampleCertificate,
} from "assets";
import { Button, OverlaySpinner } from "components";
import { Dropdown } from "flowbite-react";
import { useEffect, useMemo, useState } from "react";
import { HiDownload } from "react-icons/hi";

export function ResourcesCertificates({ resources }) {
  const [certificates, setCertificates] = useState(null);
  const [attestation, setAttestation] = useState(null);
  const [selectedStandard, setSelectedStandard] = useState("SMB1001:2025");
  const standards = ["SMB1001:2025", "SMB1001:2023"];

  useEffect(() => {
    if (resources) {
      console.log(resources);
      setCertificates({
        SMB10012023: {
          Gold: {
            image: GoldSampleCertificate,
            url: resources?.Certificate?.["2023"]?.Gold,
          },
          Silver: {
            image: SilverSampleCertificate,
            url: resources?.Certificate?.["2023"]?.Silver,
          },
          Bronze: {
            image: BronzeSampleCertificate,
            url: resources?.Certificate?.["2023"]?.Bronze,
          },
        },
        SMB10012025: {
          Diamond: {
            image: DiamondCertificate2025,
            url: resources?.Certificate?.["2025"]?.Diamond,
          },
          Platinum: {
            image: PlatinumCertificate2025,
            url: resources?.Certificate?.["2025"]?.Platinum,
          },
          Gold: {
            image: GoldCertificate2025,
            url: resources?.Certificate?.["2025"]?.Gold,
          },
          Silver: {
            image: SilverCertificate2025,
            url: resources?.Certificate?.["2025"]?.Silver,
          },
          Bronze: {
            image: BronzeCertificate2025,
            url: resources?.Certificate?.["2025"]?.Bronze,
          },
        },
      });
      setAttestation({
        SMB10012023: {
          Diamond: {
            image: GoldSampleAttestation,
            url: resources?.Attestation?.["2023"],
          },
        },
        SMB10012025: {
          Diamond: {
            image: GoldSampleAttestation,
            url: resources?.Attestation?.["2025"],
          },
        },
      });
    }
  }, [resources]);

  const selectedCertificates = useMemo(() => {
    return selectedStandard === "SMB1001:2023"
      ? certificates?.SMB10012023
      : certificates?.SMB10012025;
  }, [selectedStandard, certificates]);

  const selectedAttestation = useMemo(() => {
    return selectedStandard === "SMB1001:2023"
      ? attestation?.SMB10012023
      : attestation?.SMB10012025;
  }, [selectedStandard, attestation]);

  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [selectedAttestationItem, setSelectedAttestationItem] = useState(null);

  useEffect(() => {
    if (selectedCertificates) {
      setSelectedCertificate(Object.keys(selectedCertificates)[0]);
    }
    if (selectedAttestation) {
      setSelectedAttestationItem(Object.keys(selectedAttestation)[0]);
    }
  }, [selectedCertificates, selectedAttestation]);

  return (
    <>
      {!resources || !certificates || !attestation ? (
        <OverlaySpinner />
      ) : (
        <section className="flex flex-col gap-8">
          <div className="flex w-fit border border-primary rounded-lg p-4 !text-primary !font-semibold">
            <Dropdown label={selectedStandard} inline>
              {standards.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => setSelectedStandard(item)}
                  className="text-gray font-normal"
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>

          {/* <Alert color="yellow" icon={HiExclamation}>
            <span className="font-medium">
              Access to the certificates are limited to your current
              certification level. Please upgrade to gain access.
            </span>
          </Alert> */}

          <section className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <p className="text-base font-bold">Certificates</p>
              <p className="text-gray w-full laptop:w-1/2">
                The certificates are PDFs that can be downloaded, providing
                certifiers with proof of their certificate completion.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
              {selectedCertificate &&
                selectedCertificates[selectedCertificate] && (
                  <div className="surface-50 h-[390px] flex items-center justify-center p-8 relative rounded-lg gap-8">
                    <img
                      src={selectedCertificates[selectedCertificate].image}
                      alt={selectedCertificate}
                      className="w-[35%]"
                    />

                    <Button
                      variant="white"
                      label="Download"
                      customClasses="w-fit absolute bottom-4 left-4"
                      rightIcon={<HiDownload className="w-5 h-5 text-icons" />}
                      onClick={() => {
                        window.open(
                          selectedCertificates[selectedCertificate].url,
                          "_blank"
                        );
                      }}
                    />
                  </div>
                )}
              <div className="flex flex-col gap-8">
                <div className="flex h-fit w-fit flex-wrap gap-4 laptop:grid laptop:grid-cols-3">
                  {selectedCertificates &&
                    Object.keys(selectedCertificates).map((type, index) => (
                      <div
                        key={index}
                        className={`border-primary flex h-[80px] w-[150px] cursor-pointer items-center justify-center rounded-lg border ${
                          selectedCertificate === type
                            ? "border-green-100 bg-green-50"
                            : "text-primary bg-white"
                        }`}
                        onClick={() => setSelectedCertificate(type)}
                      >
                        <img
                          src={selectedCertificates[type].image}
                          alt={type}
                          className="w-[30%] shadow"
                        />
                      </div>
                    ))}
                </div>

                {selectedCertificate && (
                  <p className="text-icons text-xs">
                    {selectedStandard} {selectedCertificate} Certificate is
                    selected.
                  </p>
                )}
              </div>
            </div>
          </section>

          <section className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <p className="text-base font-bold">Attestation Letters</p>
              <p className="text-gray w-full laptop:w-1/2">
                The attestation letters are PDFs that can be downloaded,
                providing certifiers with proof of their certificate completion.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
              {selectedAttestationItem &&
                selectedAttestation[selectedAttestationItem] && (
                  <div className="surface-50 h-[390px] flex items-center justify-center p-8 relative rounded-lg gap-8">
                    <img
                      src={selectedAttestation[selectedAttestationItem].image}
                      alt={selectedAttestationItem}
                      className="w-[35%]"
                    />

                    <Button
                      variant="white"
                      label="Download"
                      customClasses="w-fit absolute bottom-4 left-4"
                      rightIcon={<HiDownload className="w-5 h-5 text-icons" />}
                      onClick={() => {
                        window.open(
                          selectedAttestation[selectedAttestationItem].url,
                          "_blank"
                        );
                      }}
                    />
                  </div>
                )}
              <div className="flex flex-col gap-8">
                <div className="flex h-fit w-fit flex-wrap gap-4 laptop:grid laptop:grid-cols-3">
                  {selectedAttestation &&
                    Object.keys(selectedAttestation).map((type, index) => (
                      <div
                        key={index}
                        className={`border-primary flex h-[80px] w-[150px] cursor-pointer items-center justify-center rounded-lg border ${
                          selectedAttestationItem === type
                            ? "border-green-100 bg-green-50"
                            : "text-primary bg-white"
                        }`}
                        onClick={() => setSelectedAttestationItem(type)}
                      >
                        <img
                          src={selectedAttestation[type].image}
                          alt={type}
                          className="w-[30%] shadow"
                        />
                      </div>
                    ))}
                </div>

                {selectedAttestationItem && (
                  <p className="text-icons text-xs">
                    {selectedStandard} {selectedAttestationItem} Attestation
                    Letter is selected.
                  </p>
                )}
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
}
