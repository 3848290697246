import { useState } from "react";

import { useDashboard } from "contexts";
import { BsFillBookmarksFill } from "react-icons/bs";
import {
  HiBadgeCheck,
  HiBookmark,
  HiChartPie,
  HiChatAlt2,
  HiChevronDown,
  HiChevronLeft,
  HiChevronRight,
  HiChevronUp,
  HiCog,
  HiDocumentReport,
  HiOfficeBuilding,
  HiShoppingCart,
  HiTrendingUp,
  HiUserGroup,
  HiViewGridAdd,
} from "react-icons/hi";
import { IoLogoBuffer } from "react-icons/io";
import { RiBookmarkFill, RiBookReadFill } from "react-icons/ri";
import { getAppPage } from "services";
import { openZEWidget } from "utils";
import { ROUTES } from "variables";

import { SidebarItemButton, SidebarItemLink } from "./sidebar-item";

export function Sidebar({ variant = "loggedout" }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const { dashboard } = useDashboard();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const navIconsResized = isSidebarOpen ? "nav-icons" : "nav-icons-closed";

  const commonItems = [
    {
      leftIcon: <HiChartPie className={navIconsResized} />,
      pageName: "Dashboard",
      link: ROUTES.DASHBOARD,
    },
    // {
    //   leftIcon: <HiTemplate className="nav-icons" />,
    //   pageName: "Activity Feed",
    //   link: ROUTE.ACTIVITY_FEED,
    // },
  ];

  const primeItems = [
    ...commonItems,
    {
      leftIcon: <HiOfficeBuilding className={navIconsResized} />,
      pageName: dashboard?.orgTitle,
      // rightIcon: <HiChevronDown className="nav-icons" />,
      link: ROUTES.ORGANISATIONS,
    },
    {
      leftIcon: <HiViewGridAdd className={navIconsResized} />,
      pageName: "Assessments",
      link: ROUTES.ASSESSMENTS,
    },
    // {
    //   leftIcon: <HiViewGridAdd className={navIconsResized} />,
    //   pageName: "Categorisation",
    //   link: ROUTE.CATEGORISATION,
    // },
    {
      leftIcon: <BsFillBookmarksFill className={navIconsResized} />,
      pageName: "Subscriptions",
      link: ROUTES.SUBSCRIPTIONS,
    },
    // {
    //   leftIcon: <HiDocumentReport className={navIconsResized} />,
    //   pageName: "Reports",
    //   link: ROUTE.REPORTS,
    // },
  ];

  const developerItems = [
    ...commonItems,
    {
      leftIcon: <HiTrendingUp className={navIconsResized} />,
      pageName: "Insights",
      link: ROUTES.INSIGHTS,
    },
    {
      leftIcon: <HiOfficeBuilding className={navIconsResized} />,
      pageName: "Organisations",
      rightIcon: <HiChevronDown className={navIconsResized} />,
    },
    {
      leftIcon: <HiUserGroup className={navIconsResized} />,
      pageName: "Accounts",
      rightIcon: <HiChevronDown className={navIconsResized} />,
    },
    {
      leftIcon: <HiBadgeCheck className={navIconsResized} />,
      pageName: "Partner Program",
      rightIcon: <HiChevronDown className={navIconsResized} />,
    },
    {
      leftIcon: <HiBookmark className={navIconsResized} />,
      pageName: "Certifications",
      link: ROUTES.CERTIFICATIONS,
    },
    {
      leftIcon: <HiShoppingCart className={navIconsResized} />,
      pageName: "Sales",
      link: ROUTES.SALES,
    },
    {
      leftIcon: <HiDocumentReport className={navIconsResized} />,
      pageName: "Reports",
      link: ROUTES.REPORTS,
    },
  ];

  const transitionClass = "transition-all duration-500 ease-out-back";

  return (
    <>
      {/******************** Laptop Sidebar ********************/}
      <div
        className={`surface-nav border-primary relative hidden border-r laptop:block ${transitionClass}`}
      >
        <div
          className={`flex h-full grow flex-col gap-7 self-stretch py-6 ${
            isSidebarOpen ? "w-[226px] pl-3 pr-8" : "w-[70px] pl-3 pr-5"
          } ${transitionClass}`}
        >
          {variant === "prime" ? (
            <>
              <ul className="flex flex-col gap-7">
                {primeItems.map((item, index) => (
                  <SidebarItemLink
                    key={index}
                    {...item}
                    isSidebarOpen={isSidebarOpen}
                  />
                ))}
              </ul>

              <hr className="h-px border-0 bg-gray-200 dark:bg-gray-700" />

              <ul className="flex flex-col gap-7">
                <SidebarItemLink
                  leftIcon={<RiBookmarkFill className={navIconsResized} />}
                  pageName="Certification"
                  link={getAppPage()}
                  isSidebarOpen={isSidebarOpen}
                />
              </ul>

              <hr className="border-primary" />

              <ul className="flex flex-col gap-7">
                <SidebarItemLink
                  leftIcon={<IoLogoBuffer className={navIconsResized} />}
                  pageName="Resources"
                  link={ROUTES.RESOURCES}
                  isSidebarOpen={isSidebarOpen}
                />
                <SidebarItemLink
                  leftIcon={<RiBookReadFill className={navIconsResized} />}
                  pageName="Knowledge Base"
                  link={ROUTES.KNOWLEDGE_BASE}
                  isSidebarOpen={isSidebarOpen}
                />
                <SidebarItemLink
                  leftIcon={<HiCog className={navIconsResized} />}
                  pageName="Settings"
                  link={ROUTES.ADMIN_SETTINGS}
                  isSidebarOpen={isSidebarOpen}
                />
                <SidebarItemButton
                  leftIcon={<HiChatAlt2 className={navIconsResized} />}
                  pageName="Get Help"
                  isSidebarOpen={isSidebarOpen}
                  onClick={openZEWidget}
                />
              </ul>
            </>
          ) : null}
        </div>

        <div
          className={`absolute right-0 top-4 z-50 translate-x-1/2 cursor-pointer ${transitionClass}`}
        >
          <button
            className="surface-white border-primary hover:surface-border-primary flex size-6.5 items-center justify-center rounded-full border"
            onClick={toggleSidebar}
          >
            {isSidebarOpen ? (
              <HiChevronLeft className="text-primary size-5" />
            ) : (
              <HiChevronRight className="text-primary size-5" />
            )}
          </button>
        </div>
      </div>

      {/******************** Mobile Sidebar ********************/}
      <div
        className={`surface-db-stepper relative laptop:hidden ${
          isSidebarOpen ? "top-0" : "top-[0px]"
        } ${transitionClass}`}
      >
        <div
          className={`border-primary flex h-full grow flex-col gap-0 border-b-[1px] ${
            isSidebarOpen ? "h-fit p-5" : "h-fit px-12 py-0 laptop:py-[4px]"
          } ${transitionClass}`}
        >
          {variant === "prime" ? (
            <>
              <ul className="flex-col space-y-3">
                {primeItems.map((item, index) => (
                  <SidebarItemLink
                    key={index}
                    {...item}
                    isSidebarOpen={isSidebarOpen}
                  />
                ))}
              </ul>

              <ul
                className={`flex ${isSidebarOpen ? "my-6 border-b border-t py-6" : "my-0 border-0 py-0"}`}
              >
                <SidebarItemLink
                  leftIcon={<RiBookmarkFill className={navIconsResized} />}
                  pageName="Certification"
                  link={getAppPage()}
                  isSidebarOpen={isSidebarOpen}
                />
              </ul>

              <ul className={`space-y-3 dark:border-gray-700 laptop:my-5`}>
                {/* items after divider */}
                <SidebarItemLink
                  leftIcon={<IoLogoBuffer className={navIconsResized} />}
                  pageName="Resources"
                  link={ROUTES.RESOURCES}
                  isSidebarOpen={isSidebarOpen}
                />
                <SidebarItemLink
                  leftIcon={<RiBookReadFill className={navIconsResized} />}
                  pageName="Knowledge Base"
                  link={ROUTES.KNOWLEDGE_BASE}
                  isSidebarOpen={isSidebarOpen}
                />
                <SidebarItemLink
                  leftIcon={<HiCog className={navIconsResized} />}
                  pageName="Settings"
                  link={ROUTES.ADMIN_SETTINGS}
                  isSidebarOpen={isSidebarOpen}
                />
                <SidebarItemButton
                  leftIcon={<HiChatAlt2 className={navIconsResized} />}
                  pageName="Get Help"
                  isSidebarOpen={isSidebarOpen}
                  onClick={openZEWidget}
                />
              </ul>
            </>
          ) : variant === "developer" ? (
            <>
              <ul className="space-y-0">
                {developerItems.map((item, index) => (
                  <SidebarItemLink
                    key={index}
                    {...item}
                    isSidebarOpen={isSidebarOpen}
                  />
                ))}
              </ul>
              <ul className="border-primary my-5 space-y-0 border-t pt-5 dark:border-gray-700">
                {/* items after divider */}
                <SidebarItemLink
                  leftIcon={<HiCog className={navIconsResized} />}
                  pageName="Settings"
                  link={ROUTES.ADMIN_SETTINGS}
                  isSidebarOpen={isSidebarOpen}
                />
                <SidebarItemLink
                  leftIcon={<HiChatAlt2 className={navIconsResized} />}
                  pageName="Help"
                  isSidebarOpen={isSidebarOpen}
                />
              </ul>
            </>
          ) : null}
        </div>

        <div
          className={`absolute -bottom-[25px] left-[50%] m-4 -translate-x-6 cursor-pointer ${transitionClass}`}
          onClick={toggleSidebar}
        >
          <div className="surface-white border-primary hover:surface-border-primary flex size-6.5 items-center justify-center rounded-full border">
            {isSidebarOpen ? (
              <HiChevronUp className="text-primary size-5" />
            ) : (
              <HiChevronDown className="text-primary size-5" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
