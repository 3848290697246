import { useRef, useState } from "react";

import { QRCodeGenerator } from "components";
import { Button } from "components/buttons";
import { Modal, Tooltip } from "flowbite-react";
import { HiDownload, HiLink } from "react-icons/hi";
import { MdContentCopy } from "react-icons/md";
import { RiArrowRightUpLine } from "react-icons/ri";
import { Link } from "react-router-dom";

export function QRCodeLinkModal({
  isOpen = false,
  handleCloseModal = () => {},
  type = "", // Invite or Discount
  link = "",
  discount = 0,
}) {
  const [tooltipContent, setTooltipContent] = useState("Copy Link");

  const qrCodeRef = useRef(null);

  const handleCopyInviteLink = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      setTooltipContent("Link copied!");
    });

    setTimeout(() => {
      setTooltipContent("Copy Link");
    }, 2000);
  };

  return (
    <>
      <Modal
        show={isOpen}
        size="xl"
        position="center"
        onClose={handleCloseModal}
      >
        <Modal.Header>Share your {type} Link</Modal.Header>
        <Modal.Body className="flex flex-col gap-8">
          <div className="flex flex-col gap-5 self-stretch">
            {type === "Discount" ? (
              <span className="text-gray w-full tablet:w-[85%]">
                Invite your clients to purchase their certification subscription
                with a {discount}% discount at checkout
              </span>
            ) : (
              <span className="text-gray w-full tablet:w-[85%]">
                Invite your clients to claim a certification subscription,
                deducted from your Partner tally.
              </span>
            )}

            <div className="flex w-full flex-col gap-4">
              <p className="font-medium">
                {type === "Discount" ? "Offer" : type} Link
              </p>
              <div className="flex flex-col gap-2 tablet:flex-row">
                {link ? (
                  <div className="border-primary flex w-full flex-row items-center justify-between gap-2 rounded-lg border px-3 py-2">
                    <div className="flex flex-row items-center gap-2">
                      <HiLink className="text-icons size-5 flex-shrink-0" />
                      <p className="text-gray flex tablet:hidden">
                        {link.length > 20 ? `${link.slice(0, 20)}...` : link}
                      </p>
                      <p className="text-gray hidden tablet:flex">
                        {link.length > 40 ? `${link.slice(0, 40)}...` : link}
                      </p>
                    </div>

                    <Tooltip content={tooltipContent}>
                      <button
                        className="text-icons flex size-8 flex-col items-center justify-center rounded-full bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                        onClick={() => handleCopyInviteLink(link)}
                      >
                        <MdContentCopy className="size-4" />
                      </button>
                    </Tooltip>
                  </div>
                ) : null}
                <Link
                  to={link}
                  target="_blank"
                  rel="noreferrer"
                  className="border-primary flex flex-row items-center justify-center gap-1 rounded-lg border px-4 py-2 text-black hover:text-blue-600"
                >
                  <RiArrowRightUpLine className="size-5" />
                  <span className="text-sm font-medium">View</span>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex w-full flex-row items-center justify-between gap-4 tablet:gap-2">
            <div className="h-[1px] w-1/3 bg-gray-200" />
            <div className="flex w-full justify-center tablet:w-2/3">
              <p className="text-center tablet:text-left">
                or download and share the QR code
              </p>
            </div>
            <div className="h-[1px] w-1/3 bg-gray-200" />
          </div>

          <div className="flex w-full flex-col items-center justify-center pb-4">
            <div className="rounded-lg border shadow-lg">
              <QRCodeGenerator url={link} size={5} ref={qrCodeRef} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-full laptop:w-fit"
            label={"Download"}
            leftIcon={<HiDownload className="size-4" />}
            variant={"blue"}
            onClick={() =>
              qrCodeRef.current.handleDownload(
                link?.split("/").slice(-2).join("-"),
              )
            }
          />
          <Button
            className="w-full laptop:w-fit"
            label={"Close"}
            variant={"white"}
            onClick={() => handleCloseModal(true)}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
