import { Tooltip } from "flowbite-react";
import { HiQuestionMarkCircle } from "react-icons/hi";

export function PartnerBenefitCard({ partnerBenefits }) {
  return (
    <>
      <div className="surface-db-stepper flex w-full flex-col gap-5 self-stretch rounded-lg border p-5 shadow-md laptop:order-3 h-fit">
        <div className="flex items-center gap-2 self-stretch">
          <div className="text-primary text-sm font-bold">Partner Benefits</div>
          <Tooltip
            content={
              <>
                <div className="flex flex-col gap-2">
                  <div>
                    Subscription Discount is the reduction in subscription price
                    per partner.
                  </div>
                  <div>Code Expiry is the expiration date for codes.</div>
                  <div>
                    Volume Subscription Discount is the discount based on
                    subscription volume.
                  </div>
                  <div>
                    Minimum Volume Purchase (Subscriptions) is the minimum
                    quantity for discount.
                  </div>
                </div>
              </>
            }
          >
            <HiQuestionMarkCircle className="text-icons hover:text-primary size-4 cursor-pointer" />
          </Tooltip>
        </div>

        <div className="flex flex-col self-stretch">
          {partnerBenefits.map((benefit, index) => (
            <div
              key={index}
              className="border-primary -mb-px flex flex-col items-center gap-2 self-stretch border-b py-2 text-sm laptop:flex-row"
            >
              <div className="text-gray w-full font-normal">
                {benefit.label}
              </div>

              <div className="text-sub w-full font-medium">{benefit.value}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
