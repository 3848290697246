import { useEffect, useState } from "react";

import { fetchResourceS3 } from "api";
import { Breadcrumbs } from "components";
import { PageHeader } from "layouts";
import { HiChartPie } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "variables";

import {
  ResourcesBadges,
  ResourcesBrandKit,
  ResourcesCertificates,
  ResourcesDocuments,
  ResourcesWorkbooks,
} from "./tabs";

// const TABS = ["Brand Kit", "Badges", "Documents", "Social Media"];
// const TAB_SLUGS = ["brand-kit", "badges", "documents", "social-media"];
const TABS = ["Certificates", "Badges", "Workbooks", "Brand Kit", "Documents"];
const TAB_SLUGS = [
  "certificates",
  "badges",
  "workbooks",
  "brand-kit",
  "documents",
];

export default function ResourcesPage() {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [currentTab, setCurrentTab] = useState(
    !!tab ? TAB_SLUGS.indexOf(tab) : 0,
  );
  const [certificateResources, setCertificateResources] = useState();
  const [workbookResources, setWorkbookResources] = useState();
  const [badgeResources, setBadgeResources] = useState();
  const [brandKitResources, setBrandKitResources] = useState();
  const [documentResources, setDocumentResources] = useState();

  const handleTabChange = (index) => {
    setCurrentTab(index);
    navigate(`/resources/${TAB_SLUGS[index]}`, { replace: true });
  };

  useEffect(() => {
    setup();
  }, []);

  async function setup() {
    setupCertificateResources({ setCertificateResources });
    setupBadgeResources({ setBadgeResources });
    setupBrandKitResources({ setBrandKitResources });
    setupDocumentResources({ setDocumentResources });
    setupWorkbookResources({ setWorkbookResources });
  }

  return (
    <>
      <PageHeader
        customBreadcrumbs={
          <Breadcrumbs
            icon={HiChartPie}
            items={{
              "": ROUTES.ROOT,
              Resources: ROUTES.RESOURCES,
            }}
          />
        }
        pageDescription={`Please use and download the provided resources to represent our brand in any marketing documents you create as part of our partnership.`}
        pageName="Resources"
        variant="primePage"
      />

      {/* Laptop navigation tabs */}
      <div className="surface-sub hidden h-fit w-full flex-row items-start gap-5 self-stretch rounded-lg p-4 tablet:h-[59px] tablet:items-center laptop:flex">
        {TABS?.map((item, index) => (
          <button
            key={index}
            disabled={index === currentTab}
            onClick={() => handleTabChange(index)}
            className="rounded-sm px-4 py-2 text-base font-bold outline-none ring-white focus-visible:ring-1 enabled:hover:opacity-80"
          >
            <span
              className={index === currentTab ? "text-white" : "text-icons"}
            >
              {item}
            </span>
          </button>
        ))}
      </div>

      {/* Mobile navigation tabs */}
      <div className="flex laptop:hidden">
        <select
          value={currentTab}
          onChange={(e) => handleTabChange(Number(e.target.value))}
          className="surface-sub flex h-fit w-full cursor-pointer items-start rounded-lg p-5 font-bold text-white laptop:p-4"
        >
          {TABS.map((item, index) => (
            <option
              key={index}
              value={index}
              className="text-primary bg-white font-medium"
            >
              {item}
            </option>
          ))}
        </select>
      </div>

      {
        [
          <ResourcesCertificates resources={certificateResources} />,
          <ResourcesBadges resources={badgeResources} />,
          <ResourcesWorkbooks resources={workbookResources} />,
          <ResourcesBrandKit resources={brandKitResources} />,
          <ResourcesDocuments resources={documentResources} />,
          // <ResourcesSocialMedia />,
        ][currentTab]
      }
    </>
  );
}

async function setupCertificateResources({ setCertificateResources }) {
  try {
    let resourceCertificatesS3 = await fetchResourceS3({
      type: "CERTIFICATE_DEMO",
    });
    let resourceAttestationS3 = await fetchResourceS3({
      type: "ATTESTATION_LETTER_DEMO",
    });

    setCertificateResources({
      Certificate: {
        2023: {
          Bronze: resourceCertificatesS3?.bronze_certificate_demo_2023,
          Silver: resourceCertificatesS3?.silver_certificate_demo_2023,
          Gold: resourceCertificatesS3?.gold_certificate_demo_2023,
        },
        2025: {
          Bronze: resourceCertificatesS3?.bronze_certificate_demo_2025,
          Silver: resourceCertificatesS3?.silver_certificate_demo_2025,
          Gold: resourceCertificatesS3?.gold_certificate_demo_2025,
          Platinum: resourceCertificatesS3?.platinum_certificate_demo_2025,
          Diamond: resourceCertificatesS3?.diamond_certificate_demo_2025,
        },
      },
      Attestation: {
        2023: resourceAttestationS3?.attestation_demo_2023,
        2025: resourceAttestationS3?.attestation_demo_2025,
      },
    });
  } catch (e) {
    console.error(e, e.stack);
  }
}

async function setupBadgeResources({ setBadgeResources }) {
  try {
    let resourceBadgesS3 = await fetchResourceS3({
      type: "BADGE",
    });
    setBadgeResources({
      ...resourceBadgesS3,
      downloadPackUrl: resourceBadgesS3?.badges_pack,
    });
  } catch (e) {
    console.error(e, e.stack);
  }
}

async function setupBrandKitResources({ setBrandKitResources }) {
  try {
    let resourceStyleS3 = await fetchResourceS3({
      type: "STYLE_GUIDE",
    });
    setBrandKitResources(resourceStyleS3);
  } catch (e) {
    console.error(e, e.stack);
  }
}

async function setupDocumentResources({ setDocumentResources }) {
  try {
    let workbookS3 = await fetchResourceS3({
      type: "WORKBOOK",
    });
    let overviewDeckS3 = await fetchResourceS3({
      type: "OVERVIEW_DECK",
    });
    let partnerDeckS3 = await fetchResourceS3({
      type: "PARTNER_DECK",
    });
    let clientDeckS3 = await fetchResourceS3({
      type: "CLIENT_DECK",
    });
    setDocumentResources({
      ...workbookS3,
      ...overviewDeckS3,
      ...partnerDeckS3,
      ...clientDeckS3,
    });
  } catch (e) {
    console.error(e, e.stack);
  }
}

async function setupWorkbookResources({ setWorkbookResources }) {
  try {
    let workbookS3 = await fetchResourceS3({
      type: "WORKBOOK",
    });

    console.log("workbookS3", workbookS3);
    setWorkbookResources({
      ...workbookS3,
    });
  } catch (e) {
    console.error(e, e.stack);
  }
}
