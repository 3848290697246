import { useState } from "react";

import { VideoModalTheme } from "assets";
import { ToggleButton } from "components";
import { QRCodeLinkModal } from "components/modals/qr-code-link";
import { useDashboard } from "contexts";
import { Modal, Tooltip } from "flowbite-react";
import { HiDotsVertical, HiQuestionMarkCircle } from "react-icons/hi";
import { IoMdVideocam } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "variables";

export function InsightsCertCreditsTable({
  data,
  handleInfoAlert = () => {},
  handleErrAlert = () => {},
}) {
  const { dashboard } = useDashboard();
  const { pathname } = useLocation();

  const [publicAccess, setPublicAccess] = useState(true);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);

  const handleToggleAccess = () => {
    setPublicAccess((access) => !access);
  };

  const handleVideo = () => {
    setIsVideoOpen(true);
  };

  const handleQRModal = (link) => {
    setIsQRCodeModalOpen(true);
    setSelectedLink(link);
  };

  return (
    <>
      <div className="panel-common w-full justify-between gap-8 bg-gradient-to-b from-[#f3f3f3] from-10% via-white to-white">
        <div className="border-secondary relative flex flex-col items-start justify-between gap-8 self-stretch border-b pb-5 tablet:justify-start laptop:flex-row laptop:items-center laptop:justify-between laptop:gap-8">
          <div className="flex items-center gap-2 self-stretch">
            <span className="text-secondary text-base font-bold">
              Certification Subscription Credits
            </span>

            <div className="flex items-center gap-0.5">
              {/* <Tooltip
                animation="duration-150"
                content="Video Tutorial"
                placement="bottom"
                trigger="hover"
              >
                <HiMiniPlayCircle
                  className="btn-text-green size-5 cursor-pointer"
                  onClick={handleVideo}
                />
              </Tooltip> */}

              <Tooltip
                animation="duration-150"
                content={
                  <div className="flex flex-col gap-2">
                    <span>Certification Subscription Credits</span>
                    <span className="font-normal text-gray-300">
                      The certification subscription credits widget lets you get
                      a quick overview of available credits to distribute.
                      <br />
                      <br />
                      Each certification level has custom URLs for you to send
                      to your client or supplier organisations.
                      <br />
                      <br />
                      Simply distribute the link, and any Australian business
                      can simply enter their ABN and complete registration.
                      <br />
                      <br />
                      Upon registering, the organisation will receive the
                      certification in their account, which will be deducted
                      from the widget tally and their organisation will be added
                      to your partner dashboard client or supplier list.
                    </span>
                  </div>
                }
                placement="bottom"
                trigger="click"
              >
                <HiQuestionMarkCircle className="text-icons size-5 cursor-pointer" />
              </Tooltip>
            </div>
          </div>

          <div className="flex items-center gap-2 self-stretch">
            <span className="text-primary text-3xl font-bold">
              {data?.credits?.reduce((prev, next) => prev + next.available, 0)}
            </span>

            <span className="text-primary text-sm font-semibold">credits</span>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          {data?.credits
            ?.slice()
            ?.reverse()
            ?.map((item, index) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex flex-row justify-between gap-3">
                  <div className="flex w-[120px] items-center gap-2">
                    <img src={item.image} alt={item.alt} className="size-7.5" />
                    <span className="text-primary text-base font-medium">
                      {item.name}
                    </span>
                  </div>

                  {item.link && (
                    <>
                      <Tooltip content="Manage Invite Link">
                        <button
                          className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                          onClick={() => handleQRModal(item.link)}
                        >
                          <HiDotsVertical className="size-5" />
                        </button>
                      </Tooltip>

                      {/* <Tooltip content="Copy Invite Link">
                        <button
                          className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                          onClick={() => handleCopyInviteLink(item.link)}
                        >
                          <HiLink className="size-5" />
                        </button>
                      </Tooltip>

                      <Tooltip content="Open Invite Link">
                        <Link
                          to={item.link}
                          target="_blank"
                          rel="noreferrer"
                          className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                          onClick={() =>
                            handleInfoAlert(
                              "Your invite link has been opened successfully!",
                            )
                          }
                        >
                          <RiArrowRightUpLine className="size-5" />
                        </Link>
                      </Tooltip> */}
                    </>
                  )}
                </div>

                <div className="text-icons text-lg font-medium">
                  <span
                    className={
                      !!item.available
                        ? "text-primary font-semibold"
                        : "text-icons"
                    }
                  >
                    {item.available}
                  </span>{" "}
                  <span
                    className={
                      !!item.available
                        ? "text-primary font-semibold"
                        : "text-icons"
                    }
                  >
                    / {item.total}
                  </span>
                </div>
              </div>
            ))}
        </div>

        <div className="border-secondary flex w-full flex-col items-center justify-between gap-8 border-t pt-5 tablet:flex-row">
          <div className="flex items-center gap-2">
            <span className="text-gray font-medium leading-tight">Access:</span>
            <div className="flex items-center gap-2 py-px">
              <ToggleButton
                checked={publicAccess}
                checkedText="Anyone"
                uncheckedText={`${dashboard?.orgTitle} Only`}
                handleToggle={handleToggleAccess}
              />
            </div>
          </div>

          {pathname !== ROUTES.SUBSCRIPTIONS && (
            <Link
              to={ROUTES.SUBSCRIPTIONS + "#bulk-purchase"}
              className="btn-brand w-fit gap-2 px-3 py-2 hover:bg-blue-200 hover:shadow-sm"
            >
              <span className="text-sm font-semibold">Purchase Credits</span>
            </Link>
          )}
        </div>
      </div>

      <Modal
        popup
        size="5xl"
        show={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        theme={VideoModalTheme}
      >
        <Modal.Header>
          <div className="flex items-center gap-3">
            <IoMdVideocam />
            Certification Subscription Credits
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="w-full border border-gray-300"
            src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/SubCredWidget.mp4"
            controls
            playsInline
          ></video>
        </Modal.Body>
      </Modal>

      <QRCodeLinkModal
        isOpen={isQRCodeModalOpen}
        handleCloseModal={() => setIsQRCodeModalOpen(false)}
        type={"Invite"}
        link={selectedLink}
      />
    </>
  );
}
