import { useMemo, useState } from "react";

import { CertCard, DetailRow, UpdateButton } from "components";
import { useDashboard } from "contexts";
import { HiExternalLink, HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import { getAppPage } from "services";
import { openZEWidget, parseDateShort, parseDateTimeShort } from "utils";
import { LEVELS } from "variables";

export function OrganisationDetails({
  certDetails,
  orgData,
  setCertDetails = () => { },
  handleUpdateOrgName = () => { },
}) {
  const { dashboard } = useDashboard();

  const [activeTab, setActiveTab] = useState("IN_PROGRESS");

  // Assuming orgData is available in the component
  const certProgs = orgData?.customer?.certifications || [];

  const filteredCerts = useMemo(() => {
    switch (activeTab) {
      case "IN_PROGRESS":
        return certProgs
          ?.filter((cert) => cert.progress?.status === "IN_PROGRESS")
          .sort((a, b) => b.level - a.level) // Show the highest level in progress cert
          .slice(0, 1); // Only the highest in progress cert
      case "COMPLETED":
        return certProgs
          ?.filter((cert) => cert.progress?.status === "COMPLETED")
          .sort((a, b) => b.level - a.level) // Show the highest level completed cert
          .slice(0, 1); // Only the highest completed cert
      case "HISTORY":
        const highestInProgress = certProgs
          ?.filter((cert) => cert.progress?.status === "IN_PROGRESS")
          .sort((a, b) => b.level - a.level)[0];
        const highestCompleted = certProgs
          ?.filter((cert) => cert.progress?.status === "COMPLETED")
          .sort((a, b) => b.level - a.level)[0];
        return [
          ...certProgs
            ?.filter(
              (cert) =>
                (cert.progress?.status === "COMPLETED" ||
                  cert.progress?.status === "IN_PROGRESS") &&
                cert !== highestInProgress &&
                cert !== highestCompleted
            )
            .sort((a, b) => {
              if (a.standard === b.standard) {
                return b.level - a.level;
              }
              return b.standard.localeCompare(a.standard);
            }),
        ];
      default:
        return [];
    }
  }, [activeTab, certProgs]);

  // console.log("filteredCerts", filteredCerts);
  // console.log("certProgs", certProgs);

  return (
    <>
      <div className="flex flex-col gap-8 self-stretch px-0 laptop:px-8 w-full">
        <div className="flex flex-col gap-8 self-stretch desktop:flex-row">
          <section
            id="cybercert-organisation-details-overview"
            className="laptop:w-[600px]"
          >
            <div className="flex flex-col gap-5 self-stretch">
              <span className="text-primary text-sm font-bold">
                Account Overview
              </span>

              <div className="flex flex-col self-stretch">
                {!!orgData?.data &&
                  Object.entries(orgData?.data)?.map((item, index) => (
                    <DetailRow
                      key={index}
                      label={
                        item[0] === "Organisation Name"
                          ? dashboard.orgTitleSingle + " Name"
                          : item[0]
                      }
                    >
                      {item[0] === "Organisation Name" ? (
                        <div className="flex justify-between">
                          {item[1]}
                          <UpdateButton onClick={handleUpdateOrgName} />
                        </div>
                      ) : (
                        item[1]
                      )}
                    </DetailRow>
                  ))}
              </div>
            </div>
          </section>

          {/* <TierCard /> */}
        </div>

        <section id="cybercert-organisation-details-certifications">
          <div className="flex flex-col gap-8 self-stretch">
            <span className="text-primary text-sm font-bold">
              Certifications
            </span>
            <div className="flex flex-col gap-8 rounded-lg bg-gray-100 p-6 laptop:flex-row">
              <button
                onClick={() => {
                  setActiveTab("IN_PROGRESS");
                  setCertDetails(null);
                }}
                className={`hover:text-primary rounded-lg px-2 ${activeTab === "IN_PROGRESS"
                    ? "text-primary font-semibold"
                    : "text-gray"
                  }`}
              >
                In Progress
              </button>

              <button
                onClick={() => {
                  setActiveTab("COMPLETED");
                  setCertDetails(null);
                }}
                className={`hover:text-primary rounded-lg px-2 ${activeTab === "COMPLETED"
                    ? "text-primary font-semibold"
                    : "text-gray"
                  }`}
              >
                Active
              </button>

              <button
                onClick={() => {
                  setActiveTab("HISTORY");
                  setCertDetails(null);
                }}
                className={`hover:text-primary rounded-lg px-2 ${activeTab === "HISTORY"
                    ? "text-primary font-semibold"
                    : "text-gray"
                  }`}
              >
                History
              </button>
            </div>

            {!!filteredCerts.length ? (
              <div className="flex w-full flex-wrap gap-4 ">
                {filteredCerts.map((item, index) => (
                  <>
                    <CertCard
                      ABN={item?.ABN}
                      id={item.id}
                      key={index}
                      isPast={activeTab === "HISTORY"}
                      title={item.title}
                      standard={item.standard}
                      level={item.level}
                      currentStep={item.currentStep}
                      totalSteps={item.totalSteps}
                      showCertDetails={() => {
                        if (item?.id === certDetails?.id) {
                          setCertDetails(null);
                        } else {
                          setCertDetails(item);
                        }
                      }}
                      status={item?.progress?.status}
                    />
                  </>
                ))}
              </div>
            ) : (
              <div className="flex flex-grow self-stretch">
                No certifications found for this {dashboard?.orgSingle}.
              </div>
            )}
          </div>
        </section>

        {!!certDetails && (
          <section className="flex flex-col gap-8">
            <div className="flex w-full flex-col items-start justify-between gap-8 laptop:flex-row">
              <div className="flex w-full flex-col">
                <span className="text-primary text-sm font-bold">
                  Certification details
                </span>

                {/* {console.log("certDetails", certDetails)} */}

                <DetailRow label={"Cert ID"}>
                  <span className="text-icons">{certDetails?.id}</span>
                </DetailRow>

                <DetailRow label={"Cert Progress ID"}>
                  <span className="text-icons">{certDetails?.progressId}</span>
                </DetailRow>

                <DetailRow label={"Level"}>
                  <span>
                    L{certDetails?.level}: {LEVELS[certDetails?.level]}
                  </span>
                </DetailRow>

                <DetailRow label={"Start Date"}>
                  <span>
                    {parseDateTimeShort(certDetails?.progress?.createdAt)}
                  </span>
                </DetailRow>

                <DetailRow label={"Purchased/Claimed Date"}>
                  <span>{parseDateTimeShort(certDetails?.purchasedDate)}</span>
                </DetailRow>

                <DetailRow label={"Issued Date"}>
                  <span>
                    {parseDateTimeShort(certDetails?.progress?.dateLodged)}
                  </span>
                </DetailRow>

                <DetailRow label={"Completed Date"}>
                  <span>
                    {parseDateShort(certDetails?.progress?.completedDate)}
                  </span>
                </DetailRow>

                <DetailRow label={"Expiry Date"}>
                  <span>
                    {activeTab !== "HISTORY" && certDetails?.verified ? (
                      <>
                        <div className="text-primary hover:text-link flex flex-row items-center gap-2">
                          <a href="#" target="_blank" rel="noreferrer">
                            Verify
                          </a>
                          <HiExternalLink className="size-5" />
                        </div>
                      </>
                    ) : (
                      "NA"
                    )}
                  </span>
                </DetailRow>
              </div>

              {activeTab === "HISTORY" && (
                <div className="flex flex-col h-fit w-full gap-8 rounded-lg bg-gray-50 p-5">
                  <div className="flex flex-col gap-5">
                    <p className="font-semibold">
                      {LEVELS[certDetails?.level]} Certification has been
                      archived.
                    </p>

                    <p className="text-gray">
                      This certificate has been archived, either because it was
                      not completed or it has been replaced by a higher level
                      certification.
                    </p>
                  </div>

                  <button
                    className="btn-white w-full laptop:w-fit"
                    onClick={openZEWidget}
                    type="button"
                  >
                    <HiOutlineMail className="text-icons size-4" />
                    Get Help
                  </button>
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    </>
  );
}
