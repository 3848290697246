import { useEffect, useState } from "react";

import { updatePartnerDetails } from "api";
import {
  Button,
  DetailRow,
  FaviconUpload,
  LogoUpload,
  PartnerBenefitCard,
  UpdateButton,
  UpdateOrganisationModal,
} from "components";
import { HiCheck, HiExternalLink } from "react-icons/hi";
import { parseDateShort } from "utils";

const P_TYPES = [
  "Consultant",
  "Franchise",
  "Membership",
  "MSP",
  "Supply Chain",
  "Vendor",
];
const P_PROPS = [
  "isTypeConsultant",
  "isTypeFranchise",
  "isTypeMembership",
  "isTypeMSP",
  "isTypeSupplyChain",
  "isTypeVendor",
];

export function AdminSettingsOrganisationTab({
  data,
  setIsLoading = () => {},
  handleSync = async () => {},
}) {
  const [isUpdateOrgModalOpen, setIsUpdateOrgModalOpen] = useState(false);
  const [logo, setLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);

  const handleOpenUpdateOrgModal = () => {
    setIsUpdateOrgModalOpen(true);
  };

  const handleCloseUpdateOrgModal = async (refetch = false) => {
    setIsUpdateOrgModalOpen(false);
  };

  const handleFile = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const reader = new FileReader();

    reader.onloadend = () => {
      if (event.target.name === "favicon") {
        setFavicon(reader.result);
      } else if (event.target.name === "logo") {
        setLogo(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const updateLogo = async () => {
    setIsLoading(true);
    await updatePartnerDetails({ logo });
    await handleSync();
  };

  const updateFavicon = async () => {
    setIsLoading(true);
    await updatePartnerDetails({ favicon });
    await handleSync();
  };

  useEffect(() => {
    if (!!data?.partner?.logoUrl) {
      setLogo(data?.partner?.logoUrl);
    }
    if (!!data?.partner?.faviconUrl) {
      setFavicon(data?.partner?.faviconUrl);
    }
  }, [data]);

  return (
    <>
      <section id="cybercert-admin-settings-your-organisation">
        <div className="flex flex-col gap-8 tablet:px-5">
          <div className="flex flex-col gap-8 self-stretch laptop:flex-row">
            <div className="flex w-full flex-col gap-8 self-stretch">
              <div className="flex flex-col gap-5 self-stretch">
                <span className="text-primary text-sm font-bold">
                  Program Details
                </span>

                <div className="flex flex-col self-stretch">
                  <DetailRow label={"Program Type"}>
                    <span>Cyber Assurance Program (CAP)</span>
                  </DetailRow>

                  <DetailRow label={"Partner Type"}>
                    <div className="flex w-full flex-col gap-2.5 text-sm font-medium">
                      {P_TYPES?.map(
                        (item, index) =>
                          !!data?.partner &&
                          !!data?.partner?.[P_PROPS?.[index]] && (
                            <div
                              key={index}
                              className="btn-green flex items-center gap-2.5 text-green-500 w-fit px-2 py-0.5"
                            >
                              {item}
                              <HiCheck className="size-5" />
                            </div>
                          )
                      )}
                    </div>
                  </DetailRow>
                </div>
              </div>

              <div className="flex flex-col gap-5 self-stretch">
                <div className="flex items-center gap-3 self-stretch">
                  <span className="text-primary text-sm font-bold">
                    Your Organisation
                  </span>
                  <UpdateButton onClick={handleOpenUpdateOrgModal} />
                </div>

                <div className="flex flex-col self-stretch">
                  <DetailRow label={"ABN"}>
                    <span>{data?.organisation?.ABN}</span>
                  </DetailRow>
                  <DetailRow label={"Entity Type"}>
                    <span>
                      {data?.organisation?.type
                        ? data?.organisation?.type.replace(/\b\w/g, (c) =>
                            c.toUpperCase()
                          )
                        : ""}
                    </span>
                  </DetailRow>
                  <DetailRow label={"Entity Name"}>
                    <span>{data?.organisation?.name}</span>
                  </DetailRow>
                  <DetailRow label={"Preferred Organisation Name"}>
                    <span>{data?.partner?.name}</span>
                  </DetailRow>
                  {!!data?.partner?.website && (
                    <DetailRow label={"Website"}>
                      <div className="flex w-full items-center gap-1">
                        <a
                          href={
                            data?.partner?.website?.startsWith("http://") ||
                            data?.partner?.website?.startsWith("https://")
                              ? data?.partner?.website
                              : `http://${data?.partner?.website}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-link text-sm outline-none hover:opacity-80 focus:underline"
                        >
                          {data?.partner?.website}
                        </a>
                        <HiExternalLink className="text-icons h-4.5 w-4.5" />
                      </div>
                    </DetailRow>
                  )}
                </div>
              </div>
            </div>

            <div className="flex w-full flex-col gap-8">
              <PartnerBenefitCard
                partnerBenefits={[
                  {
                    label: "Subscription Discount",
                    value: `${data?.partner?.subscriptionDiscount}%` || "NA",
                  },
                  {
                    label: "Discount Expiry",
                    value: data?.partner?.codeExpiry
                      ? parseDateShort(data?.partner?.codeExpiry)
                      : "NA",
                  },
                  ...(data?.partner?.minimumVolume &&
                  data?.partner?.volumeDiscount
                    ? [
                        {
                          label: "Minimum Volume Purchase (Subscriptions)",
                          value: data?.partner?.minimumVolume || "Nil",
                        },
                        {
                          label: "Volume Subscription Discount",
                          value: !!data?.partner?.volumeDiscount
                            ? `${data?.partner?.volumeDiscount}%`
                            : "NA",
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          </div>

          <div className="flex flex-col gap-5 self-stretch">
            <span className="text-primary text-sm font-bold">Your Logo</span>

            <div className="flex flex-col gap-8 self-stretch">
              <div className="flex flex-col gap-5 self-stretch">
                <div className="flex flex-col">
                  <span className="text-primary text-sm font-bold">
                    Upload your logo
                  </span>
                  <span className="text-gray text-sm font-normal">
                    Supported formats are PNG and JPG.
                    <br />
                    Dimensions: 60px by 500px max.
                  </span>
                </div>

                {!!logo ? (
                  <LogoUpload
                    imgSrc={logo}
                    imgAlt={`${data?.parter?.name} Logo`}
                    handleRemove={() => setLogo(null)}
                  />
                ) : (
                  <input
                    id="upload-org-logo-hidden"
                    name="logo"
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={handleFile}
                  />
                )}

                <Button
                  onClick={updateLogo}
                  customClasses="w-fit"
                  disabled={
                    data?.partner?.logoUrl === logo ||
                    (!data?.partner?.logoUrl && !logo)
                  }
                  label="Update Logo"
                  variant="white"
                />
              </div>

              <div className="flex flex-col gap-5 self-stretch">
                <div className="flex flex-col">
                  <span className="text-primary text-sm font-bold">
                    Add a favicon
                  </span>
                  <span className="text-gray text-sm font-normal">
                    Supported formats are PNG and JPG.
                    <br />
                    Dimensions: 48px by 48px max.
                  </span>
                </div>

                {!!favicon ? (
                  <FaviconUpload
                    imgSrc={favicon}
                    imgAlt={`${data?.parter?.name} Favicon`}
                    handleRemove={() => setFavicon(null)}
                  />
                ) : (
                  <input
                    id="upload-org-favicon-hidden"
                    name="favicon"
                    type="file"
                    accept="image/*"
                    multiple={false}
                    onChange={handleFile}
                  />
                )}

                <Button
                  customClasses="w-fit"
                  disabled={
                    data?.partner?.faviconUrl === favicon ||
                    (!data?.partner?.faviconUrl && !favicon)
                  }
                  label="Update Favicon"
                  variant="white"
                  onClick={updateFavicon}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateOrganisationModal
        isOpen={isUpdateOrgModalOpen}
        org={{
          ABN: data?.organisation?.ABN,
          name: data?.organisation?.name,
          industry: data?.organisation?.industry,
          preferredName: data?.partner?.name,
          website: data?.partner?.website,
        }}
        handleCloseModal={handleCloseUpdateOrgModal}
        handleSync={handleSync}
      />
    </>
  );
}
