import {
  useEffect,
  useState,
} from 'react';

import { DisplayLevelIcon } from 'components';
import { BsShieldFillExclamation } from 'react-icons/bs';
import { HiCheck } from 'react-icons/hi';

export function AssessmentsResultsPanel({
  assessmentResult,
  certificationStatus,
}) {
  const [implementedRequirements, setImplementedRequirements] = useState();

  useEffect(() => {
    if (!!assessmentResult) {
      setImplementedRequirements(
        assessmentResult?.assessment?.GAP?.map((g) => {
          const implemented = g?.filter((g) =>
            assessmentResult?.result?.implementedRequirements?.includes(
              g?.requirement,
            ),
          )?.length;
          return {
            implemented,
            total: g?.length,
            done: implemented === g?.length,
          };
        }),
      );
    }
  }, [assessmentResult]);

  return (
    <>
      <div className="surface-white border-primary flex w-full flex-col gap-8 rounded-lg border p-5 tablet:flex-row">
        <div className="flex w-full flex-col justify-between gap-8">
          <div className="flex flex-col gap-2 self-stretch">
            <span>Current Status:</span>

            <div className="flex flex-row items-center gap-0">
              {!!certificationStatus ? (
                <>
                  <DisplayLevelIcon level={certificationStatus} />
                </>
              ) : (
                <>
                  <BsShieldFillExclamation className="text-icons mr-2 h-6 w-6" />
                </>
              )}
              <h2 className="text-base font-semibold">
                {certificationStatus || "Non-Certified"}
              </h2>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <span>Qualified Level:</span>

            <div className="flex flex-row items-center gap-0">
              <DisplayLevelIcon
                level={assessmentResult?.result?.qualifiedLevel}
                size={"w-4 h-4"}
              />
              <h2 className="text-base font-bold">
                {assessmentResult?.result?.qualifiedLevel}
              </h2>
            </div>
            <p className="text-gray -mt-2 text-sm">
              {assessmentResult?.result?.result > 0 ? (
                <>
                  SMB1001:{assessmentResult?.assessment?.standard} Level{" "}
                  {assessmentResult?.result?.result}
                </>
              ) : !!assessmentResult?.result?.completedAt ? (
                <>Ineligible for minimum certification</>
              ) : (
                <>Assessment not completed</>
              )}
            </p>
          </div>
        </div>

        <div className="flex w-full flex-col gap-4">
          <h2 className="w-full text-base font-semibold">
            Requirements implemented
          </h2>

          {!!implementedRequirements && (
            <>
              {["BRONZE", "SILVER", "GOLD", "PLATINUM", "DIAMOND"]
                .slice(0, assessmentResult?.assessment?.maximumLevel)
                ?.map((levelName, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-between border-b pb-2"
                  >
                    <div>
                      <DisplayLevelIcon level={levelName} />
                      <span className="text-sm font-medium">{levelName}</span>
                    </div>
                    <div className="flex flex-row items-center gap-2">
                      {implementedRequirements[index]?.done && (
                        <HiCheck className="size-4 text-green-500" />
                      )}
                      <p>
                        <span
                          className={`${
                            implementedRequirements[index]?.done
                              ? "text-green-500"
                              : "text-red-600"
                          } font-semibold`}
                        >
                          {implementedRequirements[index]?.implemented}
                        </span>{" "}
                        / {implementedRequirements[index]?.total}
                      </p>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}
