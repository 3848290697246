import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function PartnerResourcesDocs({ resourceLinks, title }) {
  return (
    <>
      <h2 className="text-2xl font-semibold">{title}</h2>
      {resourceLinks?.partnerResources ? (
        <>
          <video className="w-full border border-gray-300" controls>
            <source src={resourceLinks.partnerResources} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        <>
          <Spinner theme={SpinnerTheme} color="darkGray" />
        </>
      )}

      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-black">Partner Resources</p>
        <p>
          In the resources page you can also find example certificates, example
          letters of attestation, high resolution copies of the 2D and animated
          3D badges, the CyberCert brand kit, and copies of the workbook and
          worksheet documents.
        </p>
      </div>
    </>
  );
}
