import { useEffect } from "react";

import { setupPartnerAccount } from "api";
import { useSyncVerifyPartnerAccount } from "hooks";

export function SpecialOfferAlert({ confettiRef, offer, button, pulse }) {
  const syncVerifyPartnerState = useSyncVerifyPartnerAccount();

  let daysLeft = getDaysLeft();

  function getDaysLeft() {
    const createdAtDate = new Date(offer?.createdAt);
    const targetDate = new Date(
      createdAtDate.getTime() + offer?.expiryDays * 24 * 60 * 60 * 1000
    );
    const currentDate = new Date();
    const differenceInMilliseconds = targetDate - currentDate;
    const differenceInDays = Math.round(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    return differenceInDays;
  }

  useEffect(() => {
    let interval;
    if (!!offer && (!offer?.expiryAccActive || daysLeft > 0) && pulse) {
      interval = setInterval(() => {
        if (confettiRef.current) {
          confettiRef.current.fire();
          clearInterval(interval);
          setupPartnerAccount({ isOfferSetup: true });
        }
      }, 100);
    }

    return () => {
      syncVerifyPartnerState();
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {!!offer && (!offer?.expiryAccActive || daysLeft > 0) && (
        <div
          className={`flex flex-col justify-between gap-8 rounded-lg bg-purple-50 p-5 laptop:flex-row laptop:items-center laptop:gap-0 
                    ${pulse && "btn-pulse-purple"}`}
        >
          <div className="flex flex-col-reverse gap-4 laptop:flex-row laptop:items-center">
            <div>
              {/* <BsRocketTakeoffFill className="size-7.5 text-purple-900" /> */}
              <span className="text-3xl flex-shrink-0">🎉</span>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex w-fit flex-row items-center gap-3 rounded-lg bg-purple-200 px-4 py-3 laptop:gap-1 laptop:px-2 laptop:py-1">
                <div className="relative flex h-3 w-3 items-center justify-center rounded-full bg-purple-300">
                  <div className="h-1.5 w-1.5 rounded-full bg-purple-600" />
                  <div className="absolute h-1.5 w-1.5 animate-ping rounded-full ring-2 ring-purple-400" />
                </div>
                <span className="text-base font-bold text-purple-600">
                  EXCLUSIVE OFFER FOR NEW PARTNERS -{" "}
                  <span className="text-red-600">
                    {" "}
                    EXPIRES IN {daysLeft} DAYS!{" "}
                  </span>
                </span>
              </div>

              <p className="text-base text-purple-700">
                Kickstart your journey with a one-time discount off bundled
                subscriptions!
              </p>
            </div>
          </div>
          {button}
        </div>
      )}
    </>
  );
}
