import { useEffect, useMemo, useState } from "react";

import {
  BronzeWorkbook2023,
  BronzeWorkbook2025,
  DiamondWorkbook2025,
  GoldWorkbook2023,
  GoldWorkbook2025,
  PlatinumWorkbook2025,
  SilverWorkbook2023,
  SilverWorkbook2025,
  WorksheetsThumbnail,
} from "assets";
import { Button, OverlaySpinner } from "components";
import { Alert, Dropdown } from "flowbite-react";
import { HiDownload, HiExclamation } from "react-icons/hi";

function hasValidUrls(items) {
  return Object.values(items).some((item) => item.url);
}

export function ResourcesWorkbooks({ resources }) {
  const [selectedStandard, setSelectedStandard] = useState("SMB1001:2025");
  const standards = ["SMB1001:2025", "SMB1001:2023"];

  const workbooks = useMemo(() => {
    return {
      SMB10012023: {
        Gold: {
          image: GoldWorkbook2023,
          url: resources?.["workbook_L3_2023"],
        },
        Silver: {
          image: SilverWorkbook2023,
          url: resources?.["workbook_L2_2023"],
        },
        Bronze: {
          image: BronzeWorkbook2023,
          url: resources?.["workbook_L1_2023"],
        },
      },
      SMB10012025: {
        Diamond: {
          image: DiamondWorkbook2025,
          url: resources?.["workbook_L5_2025"],
        },
        Platinum: {
          image: PlatinumWorkbook2025,
          url: resources?.["workbook_L4_2025"],
        },
        Gold: {
          image: GoldWorkbook2025,
          url: resources?.["workbook_L3_2025"],
        },
        Silver: {
          image: SilverWorkbook2025,
          url: resources?.["workbook_L2_2025"],
        },
        Bronze: {
          image: BronzeWorkbook2025,
          url: resources?.["workbook_L1_2025"],
        },
      },
    };
  }, [resources]);

  const worksheets = useMemo(() => {
    return {
      SMB10012023: {
        Gold: {
          name: "Gold Worksheet",
          url: resources?.["worksheet_L3_2023"],
        },
        Silver: {
          name: "Silver Worksheet",
          url: resources?.["worksheet_L2_2023"],
        },
        Bronze: {
          name: "Bronze Worksheet",
          url: resources?.["worksheet_L1_2023"],
        },
      },
      SMB10012025: {
        Diamond: {
          name: "Diamond Worksheet",
          url: resources?.["worksheet_L5_2025"],
        },
        Platinum: {
          name: "Platinum Worksheet",
          url: resources?.["worksheet_L4_2025"],
        },
        Gold: {
          name: "Gold Worksheet",
          url: resources?.["worksheet_L3_2025"],
        },
        Silver: {
          name: "Silver Worksheet",
          url: resources?.["worksheet_L2_2025"],
        },
        Bronze: {
          name: "Bronze Worksheet",
          url: resources?.["worksheet_L1_2025"],
        },
      },
    };
  }, [resources]);

  const selectedWorkbooks = useMemo(() => {
    return selectedStandard === "SMB1001:2023"
      ? workbooks.SMB10012023
      : workbooks.SMB10012025;
  }, [selectedStandard, workbooks]);

  const selectedWorksheets = useMemo(() => {
    return selectedStandard === "SMB1001:2023"
      ? worksheets.SMB10012023
      : worksheets.SMB10012025;
  }, [selectedStandard, worksheets]);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedWorksheetItem, setSelectedWorksheetItem] = useState(null);

  useEffect(() => {
    // Set selectedItem to the first key with a valid URL
    const firstValidWorkbook = Object.keys(selectedWorkbooks).find(
      (key) => selectedWorkbooks[key]?.url,
    );
    setSelectedItem(firstValidWorkbook);

    // Set selectedWorksheetItem to the first key with a valid URL
    const firstValidWorksheet = Object.keys(selectedWorksheets).find(
      (key) => selectedWorksheets[key]?.url,
    );
    setSelectedWorksheetItem(firstValidWorksheet);
  }, [selectedWorkbooks, selectedWorksheets]);

  return (
    <>
      {!resources ? (
        <OverlaySpinner />
      ) : (
        <section className="flex flex-col gap-8">
          <div className="border-primary !text-primary flex w-fit rounded-lg border p-4 !font-semibold">
            <Dropdown label={selectedStandard} inline>
              {standards.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => setSelectedStandard(item)}
                  className="text-gray font-normal"
                >
                  {item}
                </Dropdown.Item>
              ))}
            </Dropdown>
          </div>

          {!hasValidUrls(selectedWorkbooks) &&
          !hasValidUrls(selectedWorksheets) ? (
            <Alert color="yellow" icon={HiExclamation}>
              <span className="font-medium">
                Access to the workbook and worksheets are limited to your
                current certification level. Please upgrade to gain access.
              </span>
            </Alert>
          ) : (
            <>
              <section className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                  <p className="text-base font-bold">Workbooks</p>
                  <p className="text-gray w-full laptop:w-1/2">
                    The interactive workbooks are PDFs that can be edited,
                    providing certifiers with additional proof of their
                    certificate completion.
                  </p>
                </div>

                <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
                  {selectedWorkbooks[selectedItem]?.url && selectedItem && (
                    <div className="surface-50 relative flex h-[390px] items-center justify-center gap-8 rounded-lg p-8">
                      <img
                        src={selectedWorkbooks[selectedItem]?.image}
                        alt={selectedItem}
                        className="h-[80%]"
                      />

                      <Button
                        variant="white"
                        label="Download"
                        customClasses="w-fit absolute bottom-4 left-4"
                        rightIcon={
                          <HiDownload className="text-icons h-5 w-5" />
                        }
                        onClick={() => {
                          const link = document.createElement("a");
                          link.href = selectedWorkbooks[selectedItem]?.url;
                          link.download = `${selectedItem} Workbook.pdf`;
                          link.click();
                        }}
                      />
                    </div>
                  )}
                  <div className="flex flex-col gap-8">
                    <div className="flex h-fit w-fit flex-wrap gap-4 laptop:grid laptop:grid-cols-3">
                      {Object.keys(selectedWorkbooks)
                        .filter((type) => selectedWorkbooks[type]?.url)
                        .map((type, index) => (
                          <div
                            key={index}
                            className={`border-primary flex h-[80px] w-[150px] cursor-pointer items-center justify-center rounded-lg border ${
                              selectedItem === type
                                ? "border-green-100 bg-green-50"
                                : "text-primary bg-white"
                            }`}
                            onClick={() => setSelectedItem(type)}
                          >
                            <img
                              src={selectedWorkbooks[type]?.image}
                              alt={type}
                              className="w-[30%] shadow"
                            />
                          </div>
                        ))}
                    </div>

                    {selectedItem && (
                      <p className="text-icons text-xs">
                        {selectedStandard} {selectedItem} Workbook is selected.
                      </p>
                    )}
                  </div>
                </div>
              </section>

              <section className="flex flex-col gap-8">
                <div className="flex flex-col gap-2">
                  <p className="text-base font-bold">Worksheets</p>
                  <p className="text-gray w-full laptop:w-1/2">
                    The worksheet is in Excel format and can be useful for
                    certifiers as additional proof of their certificate
                    completion.
                  </p>
                </div>
                <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
                  {selectedWorksheets[selectedWorksheetItem]?.url &&
                    selectedWorksheetItem && (
                      <div className="surface-50 relative flex h-[390px] items-center justify-center gap-8 rounded-lg p-8">
                        <img
                          src={WorksheetsThumbnail}
                          alt={`${selectedStandard} ${selectedWorksheetItem} Worksheet`}
                          className="w-[35%]"
                        />

                        <Button
                          variant="white"
                          label="Download"
                          customClasses="w-fit absolute bottom-4 left-4"
                          rightIcon={
                            <HiDownload className="text-icons h-5 w-5" />
                          }
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href =
                              selectedWorksheets[selectedWorksheetItem]?.url;
                            link.download = `${selectedWorksheetItem} Worksheet.xlsx`;
                            link.click();
                          }}
                        />
                      </div>
                    )}
                  <div className="flex flex-col gap-8">
                    <div className="flex h-fit w-fit flex-wrap gap-4 laptop:grid laptop:grid-cols-3">
                      {Object.keys(selectedWorksheets)
                        .filter((type) => selectedWorksheets[type]?.url)
                        .map((type, index) => (
                          <div
                            key={index}
                            className={`border-primary flex h-[80px] w-[150px] cursor-pointer items-center justify-center rounded-lg border ${
                              selectedWorksheetItem === type
                                ? "border-green-100 bg-green-50 font-semibold text-green-600"
                                : "text-primary bg-white"
                            }`}
                            onClick={() => setSelectedWorksheetItem(type)}
                          >
                            <p>{type}</p>
                          </div>
                        ))}
                    </div>

                    {selectedWorksheetItem && (
                      <p className="text-icons text-xs">
                        {selectedStandard} {selectedWorksheetItem} Worksheet is
                        selected.
                      </p>
                    )}
                  </div>
                </div>
              </section>
            </>
          )}
        </section>
      )}
    </>
  );
}
