import {
  useRef,
  useState,
} from 'react';

import { AutoTableTheme } from 'assets';
import { FlowbitePagination } from 'components/flowbite';
import { useDashboard } from 'contexts';
import { Table } from 'flowbite-react';
import { useTableScrollPosition } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { parseDateTimeShort } from 'utils';
import {
  ICONS,
  INITIAL_GAP_ASSESSMENTS,
  LEVELS,
  ROUTES,
} from 'variables';

export function OrganisationDetailsAssessmentsTable({ data }) {
  const { dashboard } = useDashboard();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageOption, setPageOption] = useState(10);

  const tableRef = useRef(null);
  const tableScroll = useTableScrollPosition(tableRef);

  const totalPages = Math.ceil(data?.length / pageOption);

  const handlePageOptionChange = (value) => {
    setPageOption(value);
    setCurrentPage(Math.min(currentPage, Math.ceil(data?.length / value)));
  };

  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  const getAssessmentRenders = () => {
    const startIndex = (currentPage - 1) * pageOption;
    const currentAssessments = data?.slice(startIndex, startIndex + pageOption);

    return currentAssessments?.map((assessment, index) =>
      !!assessment ? <TableRow key={index} item={assessment} /> : <></>,
    );
  };

  const getAssessmentRendersMobile = () => {
    const startIndex = (currentPage - 1) * pageOption;
    const currentAssessments = data?.slice(startIndex, startIndex + pageOption);

    return currentAssessments?.map((assessment, index) =>
      !!assessment ? <MobileRow key={index} item={assessment} /> : <></>,
    );
  };

  return (
    <section className="cybercert-org-details-assess-table border-primary flex flex-col self-stretch">
      {/******************************** PC TABLE *********************************/}
      <div className="hidden laptop:block">
        <Table ref={tableRef} theme={AutoTableTheme} hoverable>
          <Table.Head>
            <Table.HeadCell
              className={`w-auto ${tableScroll.scrollLeft > 0 ? "shadow-table" : ""}`}
            >
              ID
            </Table.HeadCell>
            <Table.HeadCell>GAP ASSESSMENT</Table.HeadCell>
            <Table.HeadCell>ASSESSED TO</Table.HeadCell>
            <Table.HeadCell>SUBMISSION DATE</Table.HeadCell>
          </Table.Head>

          <Table.Body>
            {!!data?.length ? (
              getAssessmentRenders()
            ) : (
              <Table.Row>
                <Table.Cell colSpan={4} className="text-center">
                  No GAP Asessments are completed or in progress for this{" "}
                  {dashboard?.orgSingle}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      {/******************************** MOBILE TABLE *********************************/}
      <div className="block laptop:hidden">
        <div className="surface-secondary text-primary border p-4 text-xs font-semibold uppercase">
          Assessments
        </div>

        <div className="flex flex-col border-x">
          {!!data?.length ? (
            getAssessmentRendersMobile()
          ) : (
            <div className="border-b p-5 text-center">
              No GAP Asessments are completed or in progress for this{" "}
              {dashboard?.orgSingle}
            </div>
          )}
        </div>
      </div>

      {data?.length > 0 && (
        <FlowbitePagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalCount={data?.length}
          selectedOption={pageOption}
          handleOptionClick={handlePageOptionChange}
        />
      )}
    </section>
  );
}

function TableRow({ item }) {
  const navigate = useNavigate();

  return (
    <Table.Row
      className="cursor-pointer"
      onClick={() => {
        navigate(ROUTES.RESULT_GAP.replace(":id", item?.id));
      }}
    >
      <Table.Cell>
        <span className="surface-secondary text-secondary flex items-center justify-center rounded-[10px] px-2 py-1 text-xs">
          {item?.assessmentToken}
        </span>
      </Table.Cell>
      <Table.Cell>
        {[
          INITIAL_GAP_ASSESSMENTS[
            INITIAL_GAP_ASSESSMENTS.length -
              item?.assessmentDetail?.maximumLevel
          ]?.level,
        ]}
      </Table.Cell>
      <Table.Cell>
        {item?.result > 0 ? (
          <div className="flex flex-row items-center gap-2 self-stretch">
            <img
              src={ICONS[item?.result]}
              alt={`Icon Level ${item?.result}`}
              className="size-5"
            />
            <span className="uppercase">{LEVELS[item?.result]}</span>
          </div>
        ) : !!item?.createdAt ? (
          <>Ineligible for minimum certification</>
        ) : (
          <>Assessment not completed</>
        )}
      </Table.Cell>
      <Table.Cell>{parseDateTimeShort(item?.completedAt)}</Table.Cell>
    </Table.Row>
  );
}

function MobileRow({ item }) {
  return (
    <div className="hover:surface-db-stepper flex flex-col gap-4 border-b p-4">
      <div className="grid grid-cols-[auto_1fr] gap-x-4 gap-y-4">
        <span className="text-gray">ID:</span>
        <span className="surface-secondary text-secondary flex items-center justify-center rounded-[10px] px-2 py-1 text-xs">
          {item?.assessmentToken}
        </span>
        <span className="text-gray">GAP ASSESSMENT:</span>
        <span>
          {[
            INITIAL_GAP_ASSESSMENTS[
              INITIAL_GAP_ASSESSMENTS.length -
                item?.assessmentDetail?.maximumLevel
            ]?.level,
          ]}
        </span>
        <span className="text-gray">ASSESSED TO:</span>

        {console.log("ITEM", item?.createdAt)}
        <span>
          {item?.result > 0 ? (
            <div className="flex flex-row items-center gap-2 self-stretch">
              <img
                src={ICONS[item?.result]}
                alt={`Icon Level ${item?.result}`}
                className="size-5"
              />
              <span className="uppercase">{LEVELS[item?.result]}</span>
            </div>
          ) : !!item?.createdAt ? (
            <>Ineligible for minimum certification</>
          ) : (
            <>Assessment not completed</>
          )}
        </span>
        <span className="text-gray">SUBMISSION DATE:</span>
        <span>{parseDateTimeShort(item?.completedAt)}</span>
      </div>
    </div>
  );
}
