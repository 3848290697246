import { useState } from 'react';

import {
  Label,
  TextInput as FlowbiteTextInput,
} from 'flowbite-react';

export function TextInput({
  id = "",
  label = "",
  className = "",
  onChange = () => {},
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className={`relative w-full ${className}`}>
      <FlowbiteTextInput
        id={id}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        {...props}
      />

      {label && (
        <Label
          className={`absolute left-4 top-0 -translate-y-1/2 bg-gray-50 px-px text-xs ${isFocused ? "text-gray-900" : "text-gray-300"}`}
          htmlFor={id}
          value={label}
        />
      )}
    </div>
  );
}
