import { useEffect, useState } from "react";

import { verifyPartnerAccount } from "api";
import {
  AlertTheme,
  AvatarTheme,
  BadgeTheme,
  DefaultTableTheme,
  LabelTheme,
  ModalTheme,
  ProgressBarTheme,
  TextInputTheme,
  TooltipTheme,
} from "assets";
import { Hub } from "aws-amplify";
import { decode as base64_decode } from "base-64";
import { PageSpinner, RequireAuth } from "components";
import { DashboardProvider, RequireDashboard } from "contexts";
import { Flowbite } from "flowbite-react";
import { useSyncVerifyPartnerAccount, useVerifyPartnerAccount } from "hooks";
import { LayoutWrapper } from "layouts";
import {
  AddGapAssessment,
  AddOrganisations,
  AdminSettingsPage,
  AssessmentNotFoundPage,
  AssessmentProgress,
  AssessmentsPage,
  AuthMfaPage,
  AuthMfaSetupPage,
  DashboardPage,
  GapAssessmentsPage,
  MaintenancePage,
  NotFoundPage,
  OfferPage,
  OrganisationDetailsPage,
  OrganisationsPage,
  PartnerLinksPage,
  PartnerOnboarding,
  ResultGapAssessment,
  SelectDashboardPage,
  SubscriptionsPage,
  ViewGapAssessment,
} from "pages";
import { KnowledgeBasePage } from "pages/knowledge-base";
import ResourcesPage from "pages/resources/resources";
import queryString from "query-string";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AuthService, getAppPage, getLandingPage } from "services";
import { isUnauthenticatedRoute, ROUTES } from "variables";

export function Router() {
  const [isLoading, setIsLoading] = useState(false);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  const location = useLocation();
  const verifyPartnerState = useVerifyPartnerAccount({});
  const navigate = useNavigate();
  const syncPartnerState = useSyncVerifyPartnerAccount();

  useEffect(() => {
    if (!!verifyPartnerState?.data) {
      if (!!location.search) {
        parseParams();
      } else {
        setup();
      }
    } else {
      syncPartnerState();
    }
  }, [verifyPartnerState?.data, location]);

  useEffect(() => {
    return Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
        case "signOut":
        case "signIn_failure":
        case "cognitoHostedUI_failure":
        default:
      }
    });
  }, []);

  const parseParams = async () => {
    const params = queryString.parse(location.search);
    try {
      // Process login parameters
      if (!!params.c) {
        const cParams = JSON.parse(base64_decode(params["c"]));
        // check if any partner invites to accept
        if (!!cParams["partnerInvite"]) {
          sessionStorage.setItem(
            "ACCEPT_PARTNER_INVITE_BY_LOGIN",
            cParams["partnerInvite"]
          );
        }
        if (!!cParams["login"] && !!cParams["successUrl"]) {
          sessionStorage.setItem("login_success_url", cParams["successUrl"]);
        }
        if (cParams["login"] === "email" && !!cParams["u"] && !!cParams["p"]) {
          const { u, p } = cParams;

          let signinParams = [
            u,
            p,
            () => (window.location.href = ROUTES.ROOT),
            () => {},
            () =>
              navigate(ROUTES.REGISTER_CONFIRM, {
                state: {
                  registerEmail: u,
                },
              }),
            () =>
              navigate(ROUTES.MFA_VERIFY, {
                state: { email: u, password: p },
              }),
            async () => {
              const ok = await checkAccountType();
              if (ok === true) {
                setIsLoading(false);
              }
            },
          ];

          AuthService.partnerSignIn(...signinParams);
        } else {
          await setup();
        }
      } else {
        await setup();
      }
    } catch (error) {
      console.error(`ERR ${JSON.stringify(error)}`);
    }
  };

  const setup = async () => {
    const ok = await checkAccountType();
    if (ok) {
      setIsLoading(false);
    }
  };

  const checkAccountType = async () => {
    const user = await AuthService.getCurrentAuthUserInfo();
    const session = await AuthService.getCurrentAuthSession();
    if (!user && isUnauthenticatedRoute(window.location.pathname)) {
      // verify mfa
      return true;
    }
    let ok = false;
    if (!!user) {
      let verify = !verifyPartnerState?.data?.result
        ? await verifyPartnerAccount({})
        : verifyPartnerState?.data;
      const isValidPartner = verify?.result;
      if (!isValidPartner) {
        // invalid partner
        window.location.href = getAppPage();
      } else {
        // valid partner
        // check is partner setup yet?
        if (
          !verify?.partner?.isSetup &&
          window.location.pathname !== ROUTES.ONBOARDING
        ) {
          window.location.href = ROUTES.ONBOARDING;
        } else if (
          verify?.partner?.isSetup === true &&
          window.location.pathname === ROUTES.ONBOARDING
        ) {
          navigate(ROUTES.ROOT);
        } else if (
          verify?.partner?.isSetup === true &&
          (!verify?.user?.preferredMFA ||
            session?.preferredMFA !== "SOFTWARE_TOKEN_MFA") &&
          !user?.username?.includes("google") &&
          window.location.pathname !== ROUTES.MFA_SETUP
        ) {
          window.location.href = ROUTES.MFA_SETUP;
        } else if (
          verify?.partner?.isSetup === true &&
          ((!!verify?.user?.preferredMFA &&
            session?.preferredMFA === "SOFTWARE_TOKEN_MFA") ||
            user?.username?.includes("google")) &&
          window.location.pathname === ROUTES.MFA_SETUP
        ) {
          window.location.href = ROUTES.ROOT;
        } else if (verify?.partner?.isSetup === true) {
          let loginSuccessUrl = sessionStorage.getItem("login_success_url");
          if (!!loginSuccessUrl) {
            window.location.href = loginSuccessUrl;
            sessionStorage.removeItem("login_success_url");
          } else {
            ok = true;
          }
        } else {
          ok = true;
        }
      }
    } else {
      window.location.href = `${getLandingPage()}/login?success_url=${encodeURIComponent(window.location.href)}`;
    }
    return ok;
  };

  return (
    <>
      {isLoading && <PageSpinner />}

      <Flowbite
        theme={{
          theme: {
            alert: AlertTheme,
            avatar: AvatarTheme,
            badge: BadgeTheme,
            label: LabelTheme,
            modal: ModalTheme,
            progress: ProgressBarTheme,
            table: DefaultTableTheme,
            tooltip: TooltipTheme,
            textInput: TextInputTheme,
          },
        }}
      >
        <DashboardProvider>
          {/* <ReactQueryDevtools
              initialIsOpen
              buttonPosition="bottom-left"
              position="bottom"
            /> */}

          {!!isMaintenanceMode ? (
            <Routes>
              <Route path={ROUTES.MAINTENANCE} element={<MaintenancePage />} />

              <Route
                path={ROUTES.PAGE_NOT_FOUND}
                element={<Navigate to={ROUTES.MAINTENANCE} />}
              />
            </Routes>
          ) : (
            <Routes>
              <Route
                exact
                path={ROUTES.ROOT}
                element={
                  <RequireAuth>
                    <SelectDashboardPage loading={isLoading} />
                  </RequireAuth>
                }
              />
              <Route path={ROUTES.MFA_VERIFY} element={<AuthMfaPage />} />
              <Route path={ROUTES.MFA_SETUP} element={<AuthMfaSetupPage />} />
              <Route
                path={ROUTES.ONBOARDING}
                element={
                  <RequireAuth>
                    <PartnerOnboarding />
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.DASHBOARD}
                element={
                  <RequireDashboard>
                    <LayoutWrapper>
                      <DashboardPage />
                    </LayoutWrapper>
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.ADMIN_SETTINGS_DYNAMIC}
                element={
                  <RequireAuth>
                    <LayoutWrapper>
                      <AdminSettingsPage />
                    </LayoutWrapper>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ORGANISATIONS}
                element={
                  <RequireDashboard>
                    <LayoutWrapper>
                      <OrganisationsPage />
                    </LayoutWrapper>
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.ORGANISATION_DETAILS}
                element={
                  <RequireDashboard>
                    <LayoutWrapper>
                      <OrganisationDetailsPage />
                    </LayoutWrapper>
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.ADD_ORGS}
                element={
                  <RequireDashboard>
                    <AddOrganisations />
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.ASSESSMENTS}
                element={
                  <RequireDashboard>
                    <LayoutWrapper>
                      <AssessmentsPage />
                    </LayoutWrapper>
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.GAP}
                element={
                  <RequireDashboard>
                    <LayoutWrapper>
                      <GapAssessmentsPage />
                    </LayoutWrapper>
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.ADD_GAP}
                element={
                  <RequireDashboard>
                    <AddGapAssessment />
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.VIEW_GAP}
                element={
                  <RequireDashboard>
                    <LayoutWrapper>
                      <ViewGapAssessment />
                    </LayoutWrapper>
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.RESULT_GAP}
                element={
                  <RequireDashboard>
                    <LayoutWrapper>
                      <ResultGapAssessment />
                    </LayoutWrapper>
                  </RequireDashboard>
                }
              />
              <Route
                path={ROUTES.SUBSCRIPTIONS}
                element={
                  <RequireAuth>
                    <RequireDashboard>
                      <LayoutWrapper>
                        <SubscriptionsPage />
                      </LayoutWrapper>
                    </RequireDashboard>
                  </RequireAuth>
                }
              />
              <Route
                path={ROUTES.ASSESSMENT_LINK}
                element={<PartnerLinksPage />}
              />
              <Route
                path={ROUTES.ASSESSMENT_SESSION}
                element={<AssessmentProgress />}
              />

              <Route
                path={ROUTES.OFFER}
                element={
                  <LayoutWrapper>
                    <OfferPage />
                  </LayoutWrapper>
                }
              />
              <Route
                path={ROUTES.RESOURCES_DYNAMIC}
                element={
                  <LayoutWrapper>
                    <ResourcesPage />
                  </LayoutWrapper>
                }
              />
              <Route
                path={ROUTES.KNOWLEDGE_BASE_PARTNER}
                element={
                  <LayoutWrapper>
                    <KnowledgeBasePage />
                  </LayoutWrapper>
                }
              />
              <Route
                path={ROUTES.KNOWLEDGE_BASE_DYNAMIC}
                element={
                  <LayoutWrapper>
                    <KnowledgeBasePage />
                  </LayoutWrapper>
                }
              />
              {/* 404 */}
              <Route
                path={ROUTES.ASSESSMENT_404}
                element={<AssessmentNotFoundPage />}
              />

              <Route path={ROUTES.PAGE_NOT_FOUND} element={<NotFoundPage />} />
            </Routes>
          )}
        </DashboardProvider>
      </Flowbite>
    </>
  );
}
