import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { delay as delayFn } from './functions';

/**
 * Smoothly scrolls to a specified element on the page.
 *
 * @param {string} id - The ID of the target element to scroll to.
 * @param {number} [delay=0] - The delay before starting the scroll (in milliseconds).
 * @param {number} [offset=0] - The offset to adjust the final scroll position.
 */
export const scrollToElementSmoothly = async (id, delay = 0, offset = 0) => {
  // Wait for the delay
  await delayFn(delay);

  // Get the target element
  const target = document.getElementById(id);
  if (target) {
    // Easing function for smooth scrolling
    const easing = (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);

    // Calculate the distance from the top of the page
    const getTargetPos = (offset) =>
      target.getBoundingClientRect().top + window.scrollY - offset;

    // Scrolling function
    const scrolling = (currentTime, duration) => {
      const distance = getTargetPos(headerHeight + offset) - startPos;
      if (!startTime) startTime = currentTime;
      const progress = currentTime - startTime;
      const newPos = easing(progress / duration) * distance + startPos;
      window.scrollTo(0, newPos);
      if (progress < duration) {
        requestAnimationFrame((time) => scrolling(time, duration));
      }
    };

    // Calculate duration
    let startTime = 0; // Initialize start time
    const startPos = window.scrollY; // Get current scroll position
    const headerHeight = document.querySelector("header")?.offsetHeight || 0;
    const duration = Math.max(
      500,
      (getTargetPos(headerHeight + offset) - startPos) / 2,
    );

    // Start the scrolling
    setTimeout(
      () => requestAnimationFrame((time) => scrolling(time, duration)),
      0,
    );
  } else {
    // If the target element is not found, retry after a delay
    await delayFn(100);
    scrollToElementSmoothly(id, delay, offset);
  }
};

export const HashScroll = ({ children }) => {
  const { state, hash } = useLocation();
  console.log("HASH", hash);

  useEffect(() => {
    if (!!hash) {
      scrollToElementSmoothly(hash.substring(1), 500, 84); // 64px for header, 20px for spacing
    } else if (!!state?.hash) {
      scrollToElementSmoothly(state.hash, 500, 84);
    }
  }, [state?.hash, hash]);

  return children;
};
