export * from "./date";
export * from "./functions";
export * from "./hooks";
export * from "./input";
export * from "./scroll";
export * from "./strings";

/**
 * Function to deep clone an object or array.
 * This function creates a deep copy of the provided input, ensuring that nested objects and arrays are also cloned.
 *
 * @param {Object|Array} input - The object or array to be cloned.
 * @returns {Object|Array} - A deep copy of the input object or array.
 */
export const deepClone = (input) => {
  if (input === null || typeof input !== "object") {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map((item) => deepClone(item));
  }

  const clonedObject = {};
  for (const key in input) {
    if (input.hasOwnProperty(key)) {
      clonedObject[key] = deepClone(input[key]);
    }
  }

  return clonedObject;
};

/**
 * Formats a number into a price tag string in USD currency.
 *
 * @param {number} number - The number to be formatted.
 * @returns {string} The formatted price tag string.
 *
 * @example
 * formatPrice(3800);
 * // returns "$3,800.00"
 */
export const formatPrice = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};
