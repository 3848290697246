export function StepSlider({ currentStep, totalSteps, className }) {
  return (
    <>
      <div className={`flex items-center gap-4 self-stretch ${className}`}>
        <div className="flex h-1.5 w-full rounded-full bg-gray-200">
          <div
            className="surface-brand flex h-1.5 rounded-full"
            style={{ width: `${(currentStep / totalSteps) * 100}%` }}
          />
        </div>
        <div className="flex shrink-0 items-center text-sm font-medium text-gray-500">
          {currentStep} / {totalSteps} Steps
        </div>
      </div>
    </>
  );
}
