import { CyberCertBlackLogo } from "assets";
import { HiDeviceMobile, HiMail, HiOfficeBuilding } from "react-icons/hi";
import { useLocation } from "react-router-dom";

export function AssessmentFooter({
  requestedBy,
  requestedTo,
  requestedABN,
  representative,
  verifiedVia,
  verifiedData,
  verifiedUser,
}) {
  const { state } = useLocation();

  return (
    <div className="flex flex-col justify-between gap-8">
      <div className="flex flex-col gap-2">
        <span className="text-gray text-xs font-semibold">
          Assessment requested by:
        </span>
        <div className="flex flex-row items-center gap-2">
          <HiOfficeBuilding className="text-icons size-5" />
          <span className="text-primary text-sm font-semibold">
            {requestedBy}
          </span>
        </div>
      </div>

      {verifiedUser && !state?.preview && (
        <div className="flex flex-col gap-3">
          <span className="text-gray text-xs font-semibold">
            Assessment completed by:
          </span>

          <div className="flex flex-col gap-1 pb-2">
            <span className="text-primary text-sm font-semibold">
              {requestedTo}
            </span>
            <span className="text-gray text-sm font-medium">
              ABN <span className="text-primary">{requestedABN}</span>
            </span>
          </div>

          <div className="flex flex-col gap-1">
            <span className="text-primary text-sm font-semibold">
              {representative}
            </span>

            <div className="flex flex-row items-center gap-2">
              {verifiedVia &&
                (() => {
                  switch (verifiedVia) {
                    case "email":
                      return <HiMail className="text-icons size-5" />;
                    case "mobile":
                      return <HiDeviceMobile className="text-icons size-5" />;
                    default:
                      return null;
                  }
                })()}

              <span className="text-primary">{verifiedData}</span>
            </div>
          </div>
        </div>
      )}

      {/* <button className="flex flex-row items-center gap-2 text-blue-600 hover:text-blue-700">
          <HiQuestionMarkCircle className="size-5" />
          <span className="text-sm font-semibold">Understand your results</span>
        </button> */}

      <img
        src={CyberCertBlackLogo}
        className="w-[120px]"
        alt="CyberCert Logo"
      />
    </div>
  );
}
