import { useEffect, useState } from "react";

import { StatusLabel } from "components/labels";
import { QRCodeLinkModal } from "components/modals/qr-code-link";
import { useDashboard } from "contexts";
import { Modal, Tooltip } from "flowbite-react";
import { HiDotsVertical, HiQuestionMarkCircle } from "react-icons/hi";
import { IoMdVideocam } from "react-icons/io";
import { getLandingPage } from "services";

export function DiscountOffersInsightWidget({ data, handleCopy = () => {} }) {
  const { dashboard } = useDashboard();
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [link, setLink] = useState();

  useEffect(() => {
    setLink(`${getLandingPage()}/offer/${data?.discount?.code}`);
  }, [data]);

  const handleVideo = () => {
    setIsVideoOpen(true);
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "active":
        return <StatusLabel label="Active" variant="completed" />;
      default:
        return <StatusLabel label="Inactive" />;
    }
  };

  const handleQRModal = () => {
    setIsQRCodeModalOpen(true);
  };

  return (
    <>
      <div className="panel-common flex-grow justify-between gap-5 self-stretch">
        <div className="flex items-center justify-between self-stretch">
          <span className="text-secondary text-base font-bold">
            Discounted Offers
          </span>

          <div className="flex items-center gap-1">
            {/* <Tooltip
              animation="duration-150"
              content="Video Tutorial"
              placement="bottom"
              trigger="hover"
            >
              <HiMiniPlayCircle
                className="btn-text-green size-5 cursor-pointer"
                onClick={handleVideo}
              />
            </Tooltip> */}
            <Tooltip
              animation="duration-150"
              content={
                <div className="flex flex-col gap-2">
                  <span>Discounted Offers</span>
                  <span className="font-normal text-gray-300">
                    Every Partner dashboard has a unique branded discount link
                    for client or supplier organisation to purchase a
                    certification at a discounted rate.
                    <br />
                    <br />
                    Upon opening the link, their web browser session will show
                    discounted offer details that they can use to purchase a
                    subscription at checkout.
                    <br />
                    <br />
                    Upon registration, their client or supplier organisation
                    will be added to your partner dashboard client or supplier
                    list.
                  </span>
                </div>
              }
              placement="bottom"
              trigger="click"
            >
              <HiQuestionMarkCircle className="text-icons size-5 cursor-pointer" />
            </Tooltip>
          </div>
        </div>

        {!!data?.discount?.discount && (
          <div className="flex items-end gap-1 leading-none">
            <span className="text-3xl font-bold">{data?.discount?.used}</span>{" "}
            <span className="pb-0.5">
              out of {data?.discount?.total} {dashboard?.orgPlural} have used
              this offer
            </span>
          </div>
        )}

        <div className="border-secondary flex flex-col gap-2 border-b pb-3">
          <div className="flex flex-col items-start gap-1 tablet:flex-row">
            {!!data?.discount?.discount ? (
              <>
                <p className="text-lg font-bold">{data?.discount?.discount}</p>

                <p className="text-gray leading-[27px]">
                  Discount in checkout for Bronze, Silver and Gold subscriptions
                </p>
              </>
            ) : (
              <p className="text-lg font-bold">N/A</p>
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="flex gap-2">
              {getStatusLabel(data?.discount?.status)}
              {!!data?.discount?.expiry && data?.discount?.expiry !== "NA" && (
                <span className="border-secondary text-secondary flex items-center justify-center rounded-md border px-2.5 py-0.5 text-xs font-semibold">
                  Expires in {data?.discount?.expiry}
                </span>
              )}
            </div>

            {!!data?.discount?.code && (
              <div className="flex items-center gap-3">
                <span className="surface-secondary text-secondary flex items-center justify-center gap-2.5 rounded-[10px] px-2 py-1 text-xs">
                  ...
                  {data?.discount?.code?.substring(
                    data?.discount?.code?.length - 8,
                  )}
                </span>

                {/* <Tooltip content="Copy Link">
                    <button
                      className="border-secondary text-icons rounded-full border bg-gray-100 px-2 py-2 hover:bg-green-200 hover:text-green-500"
                      onClick={handleCopy}
                    >
                      <HiLink className="size-5" />
                    </button>
                  </Tooltip> */}

                <Tooltip content="Manage Invite Link">
                  <button
                    className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                    onClick={() => handleQRModal()}
                  >
                    <HiDotsVertical className="size-5" />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        popup
        size="5xl"
        show={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        theme={{ header: { popup: "border-b-0" } }}
      >
        <Modal.Header>
          <div className="flex items-center gap-3">
            <IoMdVideocam />
            <span className="text-secondary">Discounted Offers</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="w-full border border-gray-300"
            src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/DiscountedOffers.mp4"
            controls
            playsInline
          ></video>
        </Modal.Body>
      </Modal>

      <QRCodeLinkModal
        isOpen={isQRCodeModalOpen}
        handleCloseModal={() => setIsQRCodeModalOpen(false)}
        type={"Discount"}
        link={link}
        discount={data?.discount?.discount}
      />
    </>
  );
}
