import {
  BronzeDefault,
  DiamondDefault,
  GoldDefault,
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
  PlatinumDefault,
  SilverDefault,
} from "assets";

export const CERT_LVL_BADGES = {
  1: BronzeDefault,
  2: SilverDefault,
  3: GoldDefault,
  4: PlatinumDefault,
  5: DiamondDefault,
};

export const CERT_LVLS = {
  1: "Bronze",
  2: "Silver",
  3: "Gold",
  4: "Platinum",
  5: "Diamond",
};

export const CERT_LVLS_MAP = {
  Bronze: 1,
  Silver: 2,
  Gold: 3,
  Platinum: 4,
  Diamond: 5,
};

export const CERT_LVLS_PRICES = {
  1: 95,
  2: 195,
  3: 395,
  4: 3595,
  5: 5995,
};

export const ICONS = [
  null,
  IconBronze,
  IconSilver,
  IconGold,
  IconPlatinum,
  IconDiamond,
];

export const LEVELS = [null, "Bronze", "Silver", "Gold", "Platinum", "Diamond"];

export const LEVELS_MAP = {
  BLANK: 0,
  BRONZE: 1,
  SILVER: 2,
  GOLD: 3,
  PLATINUM: 4,
  DIAMOND: 5,
};

export const ROUTES = {
  ROOT: "/",
  STYLE_TILES: "/style-tiles",
  DASHBOARD: "/dashboard",
  INSIGHTS: "/insights",
  ACTIVITY_FEED: "/activities",
  ORGANISATIONS: "/organisations",
  ORGANISATION_DETAILS: "/organisations/:ABN",
  ADD_ORGS: "/organisations/add",
  PARTNER_RULES: "/rules",
  COMMISSIONS: "/commissions",
  REGISTRATION: "/auth/register",
  LOGIN: "/auth/login",
  MFA: "/auth/mfa",
  MFA_VERIFY: "/mfa/verify",
  MFA_SETUP: "/mfa/setup",
  CERTIFICATIONS: "/certifications",
  ADMIN_USERS: "/admin/users",
  PARTNERS: "/partners",
  REPORTS: "/reports",
  USERS: "/users",
  SALES: "/sales",
  DISCOUNTS: "/discounts",
  NOTIFICATIONS: "/notifications",
  CUSTOMERS: "/customers",
  ONBOARDING: "/welcome",
  CATEGORISATION: "/categorise",
  SUBSCRIPTIONS: "/subscriptions",
  SELECT_DASHBOARD: "/",
  ASSESSMENTS: "/assessments",
  GAP: "/assessments/gap",
  ADD_GAP: "/assessments/gap/add",
  VIEW_GAP: "/assessments/gap/:id",
  RESULT_GAP: "/assessments/gap/result/:id",
  ASSESSMENT_LINK: "/assessments/gap/distribution/:token",
  ASSESSMENT_SESSION: "/assessments/gap/distribution/:distribution/:token",
  ASSESSMENT_404: "/assessments/404",
  ADMIN_SETTINGS: "/admin/settings",
  ADMIN_SETTINGS_DYNAMIC: "/admin/settings/:tab?",
  OFFER: "/offer/:type?",
  RESOURCES: "/resources",
  RESOURCES_DYNAMIC: "/resources/:tab?",
  KNOWLEDGE_BASE: "/knowledge-base",
  KNOWLEDGE_BASE_DYNAMIC: "/knowledge-base/:tab?",
  KNOWLEDGE_BASE_PARTNER: "/knowledge-base/partner/:tab?",
  MAINTENANCE: "/maintenance",
  TEST_JEST: "/test-jest",
  PAGE_NOT_FOUND: "*",
};

export const UNAUTHENTICATED_ROUTES = [
  // allow unauthenticated users
  ROUTES.MFA_VERIFY,
  ROUTES.ASSESSMENT_LINK,
  ROUTES.ASSESSMENT_SESSION,
  ROUTES.ASSESSMENT_404,
];

export const LEVEL_PRICES = { Bronze: 95, Silver: 205, Gold: 395 };

export const isUnauthenticatedRoute = (pathname) => {
  const regexes = UNAUTHENTICATED_ROUTES.map((pattern) => {
    let regexPattern = pattern
      .replace(/:token/g, "[A-Za-z0-9]+")
      .replace(/:distribution/g, "[A-Za-z0-9]+")
      .replace(/\//g, "\\/");
    return new RegExp(`^${regexPattern}$`);
  });

  return regexes.some((regex) => {
    return regex.test(pathname);
  });
};
