import {
  ClientDeckThumbnail,
  OverviewDeckThumbnail,
  PartnerDeckThumbnail,
} from "assets";
import { OverlaySpinner } from "components";

import { ResourceCard } from "./";

export function ResourcesDocuments({ resources }) {
  return (
    <>
      {!resources ? (
        <OverlaySpinner />
      ) : (
        <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
          <ResourceCard
            title="MSP Partner Deck"
            description="Gain insight into the Partner program, the benefits, and access to playbooks."
            img={PartnerDeckThumbnail}
            classNameImg="w-full mx-10 my-10 object-contain"
            classNamePanel="h-fit"
            onClick={() => {
              !!resources["partner_deck"] &&
                window.open(resources["partner_deck"], "_blank");
            }}
          />
          <ResourceCard
            title="SMB1001 Overview Deck"
            description="Explain why SMB1001 was created and what problems it is solving."
            img={OverviewDeckThumbnail}
            classNameImg="w-full mx-10 my-10 object-contain"
            classNamePanel="h-fit"
            onClick={() => {
              !!resources["overview_deck"] &&
                window.open(resources["overview_deck"], "_blank");
            }}
          />
          <ResourceCard
            title="CyberCert User Deck"
            description="Educate your clients on certification and which options are available to them."
            img={ClientDeckThumbnail}
            classNameImg="w-full mx-10 my-10 object-contain"
            classNamePanel="h-fit"
            onClick={() => {
              !!resources["client_deck"] &&
                window.open(resources["client_deck"], "_blank");
            }}
          />
          {/* <ResourceCard
            title="Workbooks"
            description="The interactive workbooks are PDFs that can be edited, providing certifiers with additional proof of their certificate completion."
            img={WorkbookThumbnail}
            classNameImg={"w-full scale-75"}
            onClick={() => {
              !!resources["workbook_pack"] &&
                window.open(resources["workbook_pack"], "_blank");
            }}
          />
          <ResourceCard
            title="Worksheets"
            description="The worksheet is in Excel format and can be useful for certifiers as additional proof of their certificate completion."
            img={WorksheetsThumbnail}
            classNameImg={"scale-75"}
            onClick={() => {
              !!resources["worksheet_pack"] &&
                window.open(resources["worksheet_pack"], "_blank");
            }}
          /> */}
        </div>
      )}
    </>
  );
}
