/**
 * Custom hook to parse query parameters from the URL.
 *
 * This hook uses the `useLocation` hook from `react-router-dom` to access the current location's search string.
 * It then creates a `URLSearchParams` object to easily iterate over the query parameters and store them in an object.
 *
 * @returns {Object} An object containing the query parameters as key-value pairs.
 *
 * @example
 * // If the URL is: http://example.com/?name=John&age=30
 * const queryParams = useQueryParams();
 * console.log(queryParams); // { name: 'John', age: '30' }
 */
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const params = {};
  queryParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};
