import { useEffect, useState } from "react";

import { fetchSupplierDetails } from "api/functions/partner/fetch";
import { PlaceholderAvatar } from "assets";
import {
  Button,
  LoadingSpinner,
  RecentActivityCard,
  UpdateOrgNameModal,
} from "components";
import { Breadcrumbs } from "components/flowbite";
import { useDashboard } from "contexts";
import {
  HiArrowLeft,
  HiChartPie,
  HiChevronDown,
  HiOfficeBuilding,
} from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { capitalize } from "services";
import { parseDateTimeShort } from "utils";
import { LEVELS, ROUTES } from "variables";

import { OrganisationDetailsGap } from "./organisation-details-gap";
import { OrganisationDetails } from "./organisation-details-overview";

export function OrganisationDetailsPage({}) {
  const { dashboard } = useDashboard();
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [orgData, setOrgData] = useState();
  const [avatar, setAvatar] = useState();
  const [certDetails, setCertDetails] = useState();
  const [assessmentDetails, setAssessmentDetails] = useState();
  const [activitiesFinished, setActivitiesFinished] = useState(false);
  const [isUpdateOrgModalOpen, setIsUpdateOrgModalOpen] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const tabs = [`${dashboard?.orgTitleSingle} Details`, "Assessments"];

  const getCertName = (level) => {
    return `${LEVELS[level]} (SMB1001:L${level})`;
  };

  const handleAssessmentDetails = (index) => {
    // setAssessmentDetails(true);
  };

  const handleOpenUpdateOrgNameModal = () => {
    setIsUpdateOrgModalOpen(true);
  };

  const handleCloseUpdateOrgNameModal = (refetch = false) => {
    setIsUpdateOrgModalOpen(false);

    if (refetch) {
      setup();
    }
  };

  const setup = async () => {
    // setIsLoading(true);
    const ABN = params?.ABN;
    if (!!ABN) {
      const org = await fetchSupplierDetails({ supplierABN: ABN });

      // let highestCertified = org?.customer?.certifications
      //   ?.filter((cert) => cert?.progress?.status === "COMPLETED")
      //   ?.reduce((prev, current) => {
      //     return prev?.level > current?.level ? prev : current;
      //   }, null)?.level;
      let updatedOrgData = {
        ...org,
        data: {
          // "Current Certificate": !!org?.customer?.certification?.level
          //   ? getCertName(org?.customer?.certification?.level)
          //   : "None",
          // "Highest Certified": !!highestCertified
          //   ? getCertName(highestCertified)
          //   : "None", // "Bronze (SMB1001:L1)",
          "Organisation Name": capitalize(
            org?.customer?.addedAs || org?.customer?.name
          ),
          ABN: org?.customer?.ABN,
          "Entity Name": org?.customer?.name,
          "Entity Sector": capitalize(org?.customer?.industry),
          Location: `${org?.customer?.addressState}, ${org?.customer?.addressPostcode}`,
          User: !!org?.users[0]
            ? `${org?.users[0]?.firstName} ${org?.users[0]?.lastName}`
            : `NA`,
          Email: org?.users[0]?.email || org?.customer?.email || "NA",
          Phone: org?.users[0]?.phone || org?.customer?.phone || "NA",
          "Last Login": !!org?.users[0]?.loginAt
            ? parseDateTimeShort(org?.users[0]?.loginAt)
            : "NA", // "TODO: e.g. Yesterday, 11:02 PM, Sydney, NSW",
          Device: org?.users[0]?.loginDevice,
        },
        customer: {
          ...org?.customer,
          certifications: org?.customer?.certifications?.map((cert) => ({
            ...cert,
            title: LEVELS[cert?.level],
            standard: cert?.standard,
            level: cert?.level,
            currentStep: cert?.progress?.step,
            totalSteps: cert?.totalStep,
          })),
          activities: org?.customer?.activities?.map((activity) => ({
            ...activity,
            date: activity?.createdAt,
          })),
          assessmentResults: org?.customer?.assessmentResults?.map((r) => ({
            ...r,
            title: "SMB1001 GAP Assessment",
            subtitle:
              !!r?.result && !!r?.implementedRequirements
                ? `Completed on ${parseDateTimeShort(
                    r?.completedAt
                  )} by ${r?.completedBy}`
                : `Last accessed on ${parseDateTimeShort(
                    r?.verifiedAt
                  )} by ${r?.completedBy}`,
            progress:
              r?.answers?.length > 0
                ? 100.0 *
                  ((r?.answers?.filter((a) => !!a?.value)?.length || 0) /
                    r?.answers?.length)
                : -1,
            detail: {
              completed: r?.completedBy,
              verified: r?.verifiedData,
              date: parseDateTimeShort(r?.verifiedAt),
              result: r?.result,
              responses: r?.answers?.map((a) => ({
                id: a?.id,
                question: a?.requirement,
                answer: a?.value,
              })),
            },
          })),
        },
      };
      setAssessmentDetails(updatedOrgData?.customer?.assessmentResults);
      setOrgData(updatedOrgData);
      setActivitiesFinished(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setup();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="hidden laptop:block">
            <Breadcrumbs
              icon={HiChartPie}
              items={{
                "": ROUTES.ROOT,
                [`${dashboard?.orgTitle}`]: ROUTES.ORGANISATIONS,
                [`${capitalize(orgData?.customer?.addedAs || orgData?.customer?.name)}`]:
                  "",
              }}
            />
          </div>

          <section id="cybercert-organisation-details-back">
            <Button
              customClasses={`focus:ring-4 focus:ring-blue-300`}
              onClick={() => {
                navigate(ROUTES.ORGANISATIONS);
              }}
              leftIcon={<HiArrowLeft className="size-4" />}
              label={`Back to All ${dashboard?.orgTitle}`}
              labelStyles={`font-semibold`}
            />
          </section>

          <section id="cybercert-organisation-details-org-header">
            <div className="flex flex-col self-stretch">
              <div className="surface-db-stepper flex flex-row items-center gap-4 self-stretch rounded-t-lg px-5 pb-8 pt-5 shadow">
                <div className="border-secondary size-15 flex items-center justify-center rounded-full border">
                  {!!avatar ? (
                    <img
                      src={avatar}
                      alt={`${orgData?.customer?.addedAs || orgData?.customer?.name} Avatar`}
                      className="h-7 w-7"
                    />
                  ) : (
                    <HiOfficeBuilding className="text-icons h-7 w-7" />
                  )}
                </div>
                <div className="my-auto flex flex-col gap-1 self-stretch">
                  <span className="text-primary text-lg font-semibold">
                    {capitalize(
                      orgData?.customer?.addedAs || orgData?.customer?.name
                    )}
                  </span>

                  <span className="text-gray text-sm leading-none">
                    <span className="font-semibold">ABN</span>{" "}
                    {orgData?.customer?.ABN}
                  </span>
                </div>
              </div>

              <div className="surface-sub flex h-[59px] items-center gap-5 self-stretch rounded-b-lg p-4">
                {tabs?.map((item, index) => (
                  <button
                    key={index}
                    disabled={index === currentTab}
                    className="rounded-lg px-4 py-2 text-base font-bold outline-none ring-white focus-visible:ring-1 enabled:hover:opacity-80"
                    onClick={() => setCurrentTab(index)}
                  >
                    <span
                      className={
                        index === currentTab ? "text-white" : "text-icons"
                      }
                    >
                      {item}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </section>

          {
            [
              <OrganisationDetails
                certDetails={certDetails}
                orgData={orgData}
                setCertDetails={setCertDetails}
                handleUpdateOrgName={handleOpenUpdateOrgNameModal}
              />,
              <OrganisationDetailsGap
                assessmentDetails={assessmentDetails}
                orgData={orgData}
                setAssessmentDetails={setAssessmentDetails}
                handleAssessmentDetails={handleAssessmentDetails}
              />,
              <>
                <section id="cybercert-organisation-details-recent-activity">
                  <div className="flex flex-col gap-4 self-stretch">
                    {orgData?.customer?.activities?.map((item, index) => (
                      <RecentActivityCard
                        key={index}
                        avatar={PlaceholderAvatar}
                        name={item.name}
                        id={item.id}
                        date={item.date}
                        msg={item.msg}
                      />
                    ))}

                    {activitiesFinished ? (
                      <div className="text-primary text-center text-sm font-medium">
                        You have reached the end! No more activities to load.
                      </div>
                    ) : (
                      <button
                        className="mx-auto flex w-fit items-center justify-center gap-2 self-stretch rounded-lg px-3 py-2 hover:bg-gray-300 hover:shadow-sm focus:ring-2 focus:ring-gray-900"
                        // onClick={handleLoadMore}
                      >
                        <span className="text-primary text-sm font-semibold">
                          Load More
                        </span>
                        <HiChevronDown className="size-4" />
                      </button>
                    )}
                  </div>
                </section>
              </>,
            ][currentTab]
          }

          <UpdateOrgNameModal
            isOpen={isUpdateOrgModalOpen}
            orgABN={orgData?.data?.["ABN"]}
            orgName={orgData?.data?.["Organisation Name"]}
            handleCloseModal={handleCloseUpdateOrgNameModal}
          />
        </>
      )}
    </>
  );
}
