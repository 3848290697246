import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { openPartnerBulkCheckoutSession } from 'api';
import { BulkPurchaseTable } from 'components';
import { useDashboard } from 'contexts';
import { useQueryParams } from 'hooks';
import { CgSpinner } from 'react-icons/cg';
import { HiCheck } from 'react-icons/hi';
import { HiNoSymbol } from 'react-icons/hi2';
import {
  deepClone,
  formatPrice,
} from 'utils';
import {
  CERT_LVLS,
  CERT_LVLS_PRICES,
  ICONS,
} from 'variables';

const INIT_STATE = {
  qtyBronze: 0,
  qtySilver: 0,
  qtyGold: 0,
  qtyPlatinum: 0,
  qtyDiamond: 0,
};

export function BulkPurchaseCard({
  subscriptionDiscount = 0,
  volumeDiscount = 0,
  volumeAmount = 0,
}) {
  const [bulkPurchase, setBulkPurchase] = useState(deepClone(INIT_STATE));
  const [isLoading, setIsLoading] = useState(false);

  const { dashboard } = useDashboard();
  const { level } = useQueryParams();

  const handleInput = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    if (value === "" || /^[0-9]*$/.test(value)) {
      setBulkPurchase((prev) => ({
        ...prev,
        [name]: value !== "" ? parseInt(value) : 0,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    await openPartnerBulkCheckoutSession({
      products: {
        bronze: bulkPurchase.qtyBronze,
        silver: bulkPurchase.qtySilver,
        gold: bulkPurchase.qtyGold,
        platinum: bulkPurchase.qtyPlatinum,
        diamond: bulkPurchase.qtyDiamond,
      },
      dashboard: dashboard.route,
    });
    setIsLoading(false);
  };

  const discountableTotalQty = useMemo(() => {
    return Object.keys(CERT_LVLS).reduce((acc, level) => {
      return (
        acc +
        (["4", "5", 4, 5].includes(level)
          ? 0
          : bulkPurchase[`qty${CERT_LVLS[level]}`])
      );
    }, 0);
  }, [bulkPurchase]);

  const totalQty = useMemo(() => {
    return Object.keys(CERT_LVLS).reduce((acc, level) => {
      return acc + bulkPurchase[`qty${CERT_LVLS[level]}`];
    }, 0);
  }, [bulkPurchase]);

  const isEnough = useMemo(() => {
    return (
      discountableTotalQty >= volumeAmount &&
      volumeAmount > 0 &&
      volumeDiscount > 0
    );
  }, [discountableTotalQty, volumeAmount]);

  const subtotalLevels1To3 = useMemo(() => {
    return Object.keys(CERT_LVLS).reduce((acc, level) => {
      return (
        acc +
        (["1", "2", "3"].includes(level)
          ? CERT_LVLS_PRICES[level] * bulkPurchase[`qty${CERT_LVLS[level]}`]
          : 0)
      );
    }, 0);
  }, [bulkPurchase]);

  const discountableSubtotal = useMemo(() => {
    return Object.keys(CERT_LVLS).reduce((acc, level) => {
      return (
        acc +
        (["4", "5", 4, 5].includes(level)
          ? 0
          : CERT_LVLS_PRICES[level] * bulkPurchase[`qty${CERT_LVLS[level]}`])
      );
    }, 0);
  }, [bulkPurchase]);

  const staticSubtotal = useMemo(() => {
    return Object.keys(CERT_LVLS).reduce((acc, level) => {
      return (
        acc +
        (["4", "5", 4, 5].includes(level)
          ? CERT_LVLS_PRICES[level] * bulkPurchase[`qty${CERT_LVLS[level]}`]
          : 0)
      );
    }, 0);
  }, [bulkPurchase]);

  const subtotal = useMemo(() => {
    return Object.keys(CERT_LVLS).reduce((acc, level) => {
      return (
        acc + CERT_LVLS_PRICES[level] * bulkPurchase[`qty${CERT_LVLS[level]}`]
      );
    }, 0);
  }, [bulkPurchase]);

  const totalPrice = useMemo(() => {
    const discountedSubtotalLevels1To3 =
      subtotalLevels1To3 *
      (1 - (isEnough ? volumeDiscount : subscriptionDiscount) / 100);
    return Math.max(0, discountedSubtotalLevels1To3 + staticSubtotal);
  }, [
    subtotalLevels1To3,
    staticSubtotal,
    isEnough,
    volumeDiscount,
    subscriptionDiscount,
  ]);

  const isWithinPaymentLimit = useMemo(() => {
    return totalPrice <= 999999.99;
  }, [totalPrice]);

  useEffect(() => {
    const qtyInput = document.getElementById(`qty${CERT_LVLS[level]}`);
    console.log(qtyInput);
    if (qtyInput) {
      qtyInput.focus();
    }
  }, [level]);

  return (
    <form
      id="bulk-purchase"
      className="border-primary surface-primary flex flex-col gap-8 self-stretch rounded-lg border p-8 shadow-md tablet:flex-row"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-1 flex-col gap-5">
        <div className="flex flex-col gap-2">
          <span className="text-secondary text-base font-bold">
            Bulk Purchase
          </span>
          {volumeDiscount > 0 && (
            <span className="text-gray text-sm">
              Get {volumeDiscount}% off total price for minimum purchase of{" "}
              {volumeAmount} Bronze, Silver or Gold combination subscriptions.
            </span>
          )}
        </div>

        <BulkPurchaseTable
          bulkPurchase={bulkPurchase}
          isLoading={isLoading}
          handleInput={handleInput}
        />

        {volumeDiscount > 0 && (
          <div className="border-primary flex justify-between rounded-lg border p-5">
            <div className="flex flex-col gap-1">
              <span className="font-semibold">
                Bulk Subscription Discount {volumeDiscount}%
              </span>
              <span className="text-sm font-medium text-gray-500">
                Minimum volume purchase of {volumeAmount} subscriptions is
                required
              </span>
            </div>

            <div
              className={`flex flex-row items-center gap-2 ${isEnough ? "text-green-500" : "text-red-500"}`}
            >
              <span className="text-icons mt-1 text-xs font-medium">QTY:</span>
              <span className={`text-lg font-bold`}>
                {discountableTotalQty}
              </span>{" "}
              {isEnough ? (
                <HiCheck className="size-4" />
              ) : (
                <HiNoSymbol className="size-4" />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="surface-db-stepper border-primary flex flex-1 flex-col gap-5 rounded-lg border p-5">
        <span className="font-bold">Order Summary</span>

        {Object.keys(CERT_LVLS).map((level) => (
          <div key={level} className="flex justify-between">
            <div className="flex flex-row items-center gap-4">
              <img
                className="pointer-events-none h-auto w-7"
                src={ICONS[level]}
                alt={`${CERT_LVLS[level]} Badge`}
              />
              <div className="flex flex-col items-start">
                <span className="font-semibold uppercase">
                  {CERT_LVLS[level]}
                </span>
                <span className="text-sm font-medium text-gray-500">
                  {formatPrice(CERT_LVLS_PRICES[level])} / Subscription
                </span>
              </div>
            </div>

            <span className="text-gray font-bold">
              {formatPrice(
                CERT_LVLS_PRICES[level] *
                  bulkPurchase[`qty${CERT_LVLS[level]}`],
              )}
            </span>
          </div>
        ))}

        <hr className="border-primary border-b border-dashed" />

        <div className="flex w-full flex-col gap-2 text-sm font-medium">
          <div className="flex flex-row items-center justify-between">
            <span className="text-gray-500">
              Subtotal for Bronze, Silver, Gold
            </span>
            <div className="flex flex-row items-center justify-between gap-2">
              {(isEnough || !!subscriptionDiscount) && (
                <div className="flex">
                  <span className="text-green-500">
                    {isEnough
                      ? `${volumeDiscount}% Discount`
                      : `${subscriptionDiscount}% Discount`}
                  </span>
                </div>
              )}

              <span className="text-gray">
                {formatPrice(
                  subtotalLevels1To3 *
                    (1 -
                      (isEnough ? volumeDiscount : subscriptionDiscount) / 100),
                )}
              </span>
              <span className="text-gray line-through">
                {formatPrice(subtotalLevels1To3)}
              </span>
            </div>
          </div>

          <div className="flex justify-between">
            <span className="text-gray-500">
              Subtotal for Platinum, Diamond
            </span>
            <span className="text-gray">{formatPrice(staticSubtotal)}</span>
          </div>

          {/* {(isEnough || !!subscriptionDiscount) && (
            <div className="flex justify-between">
              <span className="text-gray-500">
                {isEnough
                  ? `${volumeDiscount}% Discount`
                  : `${subscriptionDiscount}% Discount`}
              </span>
              <span className="text-gray line-through">
                {formatPrice(
                  ((isEnough ? volumeDiscount : subscriptionDiscount) / 100) *
                    discountableSubtotal
                )}
              </span>
            </div>
          )} */}

          <div className="flex justify-between">
            <span className="text-gray-500">Tax</span>
            <span className="text-gray text-right tablet:text-left">
              Calculated at checkout, if applicable
            </span>
          </div>

          <div className="text-primary flex justify-between text-lg font-bold">
            <span>Total</span>
            <span>{formatPrice(totalPrice)}</span>
          </div>
        </div>

        {!isWithinPaymentLimit && (
          <div className="text-right text-sm font-bold text-red-500">
            The total price exceeds the payment limit of $999,999.99.
          </div>
        )}

        <button
          className="btn-brand w-fit gap-2 self-end px-3 py-2"
          disabled={totalQty <= 0 || !isWithinPaymentLimit || isLoading}
          type="submit"
        >
          {isLoading && <CgSpinner className="size-5 animate-spin" />}
          <span className="text-sm font-bold">Checkout</span>
        </button>
      </div>
    </form>
  );
}
