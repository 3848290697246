import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

import { qrcodegen } from "./qrcodegen"; // Ensure the correct path to qrcodegen.js

/**
 * QR Code Generator
 * @param {string} url - The URL to encode in the QR code
 * @returns {React.ReactNode} - A canvas element with the QR code
 */
export const QRCodeGenerator = forwardRef((props, ref) => {
  const {
    url,
    size = 5,
    border = 1,
    lightColor = "#FFFFFF",
    darkColor = "#000000",
  } = props || {};

  const ecl = qrcodegen.QrCode.Ecc.LOW; // Choose an error correction level
  const qr = qrcodegen.QrCode.encodeText(url, ecl);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      drawCanvas(qr, size, border, lightColor, darkColor, canvasRef.current);
    }
  }, [qr]);

  async function handleDownload(fileName) {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = fileName ? `${fileName}.png` : `"qrcode.png"`;
      link.click();
    }
  }

  useImperativeHandle(ref, () => ({
    handleDownload,
  }));

  return <canvas ref={canvasRef}></canvas>;
});

/**
 * Draws the QR code on the canvas
 * https://github.com/nayuki/QR-Code-generator/blob/master/typescript-javascript/qrcodegen-output-demo.ts#L207
 * @param {*} qr
 * @param {*} scale
 * @param {*} border
 * @param {*} lightColor
 * @param {*} darkColor
 * @param {*} canvas
 */
function drawCanvas(qr, scale, border, lightColor, darkColor, canvas) {
  if (scale <= 0 || border < 0) throw new RangeError("Value out of range");
  const width = (qr.size + border * 2) * scale;
  canvas.width = width;
  canvas.height = width;
  let ctx = canvas.getContext("2d");
  for (let y = -border; y < qr.size + border; y++) {
    for (let x = -border; x < qr.size + border; x++) {
      ctx.fillStyle = qr.getModule(x, y) ? darkColor : lightColor;
      ctx.fillRect((x + border) * scale, (y + border) * scale, scale, scale);
    }
  }
}
