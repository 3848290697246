import { useState } from "react";

import { TextInputTheme } from "assets";
import { Button, TextInput } from "components";
import { HiCheck, HiOutlineMail } from "react-icons/hi";
import { validateEmail, validateEmpty, validatePhoneNumber } from "utils";

const options = [
  { value: "email", label: "Email", icon: HiOutlineMail },
  // { value: "mobile", label: "Mobile", icon: HiOutlineDeviceMobile },
];

export function CheckEligibility({
  input,
  setInput = () => {},
  setErrMsg = () => {},
  handleInput = () => {},
  handleVerify = () => {},
}) {
  const [selectedOption, setSelectedOption] = useState("email");

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let submission = {
      ...input,
      name: { error: false, value: input?.name?.value?.trim() },
      email: { error: false, value: input?.email?.value?.trim() },
      phone: { error: false, value: input?.phone?.value?.trim() },
    };

    let isInvalidField = false;
    if (!validateEmpty(submission?.name?.value)) {
      submission.name.error = true;
      isInvalidField = true;
    }
    if (
      selectedOption === "email" &&
      (!validateEmpty(submission?.email?.value) ||
        !validateEmail(submission?.email?.value))
    ) {
      submission.email.error = true;
      isInvalidField = true;
    }
    if (
      selectedOption === "phone" &&
      (!validateEmpty(submission?.phone?.value) ||
        !validatePhoneNumber(submission?.phone?.value))
    ) {
      submission.phone.error = true;
      isInvalidField = true;
    }

    setInput(submission);
    if (isInvalidField) {
      setErrMsg(
        "You have entered an invalid field. Please check and try again."
      );
    } else {
      setErrMsg(null);
      handleVerify(submission);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-8 p-8">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-0">
            <div className="text-primary w-fit text-lg font-bold">
              Check your eligibility
            </div>

            <div className="text-gray w-fit text-sm">
              Please enter your email address to confirm your eligibility to
              complete this assessment.
            </div>
          </div>

          <SelectVerification
            selectedOption={selectedOption}
            handleOptionSelect={handleOptionSelect}
          />
        </div>

        {selectedOption && (
          <EnterDetails
            input={input}
            selectedOption={selectedOption}
            handleInput={handleInput}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </>
  );
}

export function SelectVerification({ selectedOption, handleOptionSelect }) {
  return (
    <>
      <div className="flex w-full flex-col gap-2 laptop:w-[50%]">
        {/* <h3 className="text-primary text-sm font-medium">
          Get started with these options:
        </h3> */}
        <div className="flex flex-row gap-2">
          {options.map((option) => (
            <button
              key={option.value}
              className={`border-primary btn-bg btn-text flex w-[140px] flex-row items-center justify-center gap-2 rounded-lg border p-3`}
              // ${selectedOption === option.value ? "btn-bg btn-text" : "surface-white text-primary"}
              onClick={() => handleOptionSelect(option.value)}
            >
              <option.icon
                className={`size-5 ${
                  selectedOption !== option.value && "text-icons"
                }`}
              />
              <span className="text-sm font-semibold">{option.label}</span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
}

export function EnterDetails({
  input,
  selectedOption,
  handleInput = () => {},
  handleSubmit = () => {},
}) {
  return (
    <form
      className="flex w-full flex-col justify-between gap-8 self-stretch laptop:w-[50%]"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col justify-center gap-4 self-stretch laptop:gap-8">
        <div className="flex flex-col gap-2 self-stretch">
          {`Enter your ${selectedOption === "email" ? "work email address" : "mobile number"} to verify`}

          {selectedOption === "email" ? (
            <TextInput
              color={input?.email?.error ? "failure" : "gray"}
              name="email"
              type="text"
              value={input?.email?.value}
              placeholder={"Enter your work email address"}
              required
              shadow
              theme={TextInputTheme}
              onChange={handleInput}
              className={"w-3/4"}
            />
          ) : (
            <TextInput
              color={input?.phone?.error ? "failure" : "gray"}
              name="phone"
              type="text"
              value={input?.phone}
              placeholder={"Enter your mobile number"}
              required
              shadow
              theme={TextInputTheme}
              onChange={handleInput}
              className={"w-3/4"}
            />
          )}

          <span className="text-xs text-gray-400">
            Please use your registered contact details associated with this
            partner account.
          </span>
        </div>
      </div>

      <div className="flex flex-col gap-2 self-stretch">
        Enter your full name to log who is completing the assessment
        <TextInput
          color={input?.name?.error ? "failure" : "gray"}
          name="name"
          type="text"
          placeholder="Enter your full name"
          required
          shadow
          theme={TextInputTheme}
          onChange={handleInput}
          className={"w-3/4"}
        />
      </div>

      <Button
        customClasses={`w-fit laptop:flex`}
        leftIcon={<HiCheck className="size-4" />}
        label={"Submit"}
        labelStyles={"font-semibold text-sm"}
        variant={"blue"}
        type="submit"
      />
    </form>
  );
}
