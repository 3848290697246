import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function PartnerAccountManagementDocs({ resourceLinks, title }) {
  return (
    <>
      <h2 className="text-2xl font-semibold">{title}</h2>
      {resourceLinks?.accountManagement ? (
        <>
          <video className="w-full border border-gray-300" controls>
            <source src={resourceLinks?.accountManagement} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        <Spinner theme={SpinnerTheme} color="darkGray" />
      )}
      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-black">Account Management</p>
        <p>
          In settings you can manage your organisation details and add other
          admin users, assigning them access to the partner dashboard as well.
        </p>
      </div>
    </>
  );
}
